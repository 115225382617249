import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeliveryOrderDialog from '../delivery_order_dialog'
import DeliveryOrder from '../delivery_order/delivery_order'
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../../utils';

interface SimpleMenuForDeliveryOrderCardProps {
  deliveryOrder
  sellOrder
  
  bargainPrices
  bargains
  mills
  parties
  accounts
  sell_orders
  items
  qualities
  seasons
  
  handleEditDeliveryOrder
  handleDeleteDeliveryOrder
}

export default function SimpleMenuForDeliveryOrderCard(props: SimpleMenuForDeliveryOrderCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openGenerateDeliveryOrderDialog, setOpenGenerateDeliveryOrderDialog] = React.useState(false);
  const [openEditDeliveryOrderDialog, setOpenEditDeliveryOrderDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenGenerateDeliveryOrderDialog = () => {
    setOpenGenerateDeliveryOrderDialog(true);
    handleClose();
  };

  const handleOpenEditDeliveryOrderDialog = () => {
    setOpenEditDeliveryOrderDialog(true);
    handleClose();
  };
  
  const closeDialogs = () => {
    setOpenGenerateDeliveryOrderDialog(false);
    setOpenEditDeliveryOrderDialog(false);
  };

  const getAssociatedRecords = (deliveryOrder) => {
    var sellOrder = getElementFromId(props.sell_orders, deliveryOrder.sell_order_id);

    var bargain_price = getElementFromId(props.bargainPrices, sellOrder.bargain_price_id);
    var bargain = getElementFromId(props.bargains, bargain_price.bargain_id);
    var mill = getElementFromId(props.mills, bargain.mill_id);
    var account = getElementFromId(props.accounts, sellOrder.account_id);
    var bill_to_party = getElementFromId(props.parties, deliveryOrder.bill_to_id);
    var ship_to_party = getElementFromId(props.parties, deliveryOrder.ship_to_id);
    return {
      bargain_price: bargain_price,
      bargain: bargain,
      mill: mill,
      account: account,
      bill_to_party: bill_to_party,
      ship_to_party: ship_to_party,
    }
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={()=> window.open(`/delivery_orders/${props.deliveryOrder.id}.pdf`, '_blank')}>View D.O.</MenuItem>
        <MenuItem onClick={handleOpenEditDeliveryOrderDialog}>Edit D.O.</MenuItem>
        <MenuItem onClick={() => props.handleDeleteDeliveryOrder(props.deliveryOrder.id)}>Delete D.O.</MenuItem>
      </Menu>
      <DeliveryOrder 
        open={openGenerateDeliveryOrderDialog}
        onClose={closeDialogs}
        deliveryOrder={props.deliveryOrder} 
        mill={getAssociatedRecords(props.deliveryOrder)['mill']}
        account={getAssociatedRecords(props.deliveryOrder)['account']}
        bill_to_party={getAssociatedRecords(props.deliveryOrder)['bill_to_party']}
        ship_to_party={getAssociatedRecords(props.deliveryOrder)['ship_to_party']}
      />
      <DeliveryOrderDialog 
        open={openEditDeliveryOrderDialog}
        onClose={closeDialogs}
        dialogTitle="Edit Delivery Order"
        action="edit"
        
        deliveryOrder={props.deliveryOrder}
        sellOrder={props.sellOrder}
        
        parties={props.parties}
        accounts={props.accounts}
        items={props.items}
        qualities={props.qualities}
        seasons={props.seasons}
        
        handleSubmit={props.handleEditDeliveryOrder}
      />
    </React.Fragment>
  );
};