import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormatDate from '../date_formatter'
import { getNameFromId, getIdFromName } from '../utils'
import EditIcon from '@material-ui/icons/Edit';
import ShopIcon from '@material-ui/icons/Shop';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface BargainsDialogProps {
  bargain?
  accounts
  mills
  handleSubmit
  buttonVariant
  buttonColor
  buttonText
  dialogTitle
  action
};

export default function BargainsDialog(props: BargainsDialogProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [id, setId] = useState('');
  const [accountName, setAccountName] = useState('');
  const [millName, setMillName] = useState('');

  const quantityRef = useRef(null);
  const lateLiftingPenaltyRef = useRef(null);

  const [purchaseDate, setPurchaseDate] = React.useState<Date | null>(
    new Date(),
  );

  const [dueDate, setDueDate] = React.useState<Date | null>(
    new Date(),
  );

  const handlePurchaseDateChange = (date: Date | null) => {
    setPurchaseDate(date);
  };

  const handleDueDateChange = (date: Date | null) => {
    setDueDate(date);
  };

  const handleClickOpen = () => {
    setOpen(true);
    props.bargain && props.bargain.id && setId(props.bargain.id);
    props.bargain && props.bargain.account_id && setAccountName(getNameFromId(props.accounts, props.bargain.account_id));
    props.bargain && props.bargain.mill_id && setMillName(getNameFromId(props.mills, props.bargain.mill_id));
    props.bargain && props.bargain.purchase_date && setPurchaseDate(props.bargain.purchase_date);
    props.bargain && props.bargain.due_date && setDueDate(props.bargain.due_date);
    setShowAlert(false);
  };

  const handleClose = () => {
    setShowAlert(false);
    setOpen(false);
  };

  const handleSubmit = () => {
    if(accountName === '' || millName === '' || quantityRef.current.value === '') {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      setOpen(false);
      props.handleSubmit(constructResponseMap());
    }
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('bargain_id', id);
    temp.set('account_id', getIdFromName(props.accounts, accountName));
    temp.set('mill_id', getIdFromName(props.mills, millName));
    temp.set('quantity_in_quintals', quantityRef.current.value);
    temp.set('late_lifting_penalty_per_quintal', lateLiftingPenaltyRef.current.value);
    temp.set('purchase_date', FormatDate(purchaseDate));
    temp.set('due_date', FormatDate(dueDate));
    return temp;
  };

  return (
    <React.Fragment>
      <Button 
        className={classes.button}
        variant={props.buttonVariant} 
        size="small" 
        color={props.buttonColor} 
        onClick={handleClickOpen}
        startIcon={props.action === 'create' ? <ShopIcon /> : <EditIcon />}
      >
        {props.buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">Please enter a value for all fields</Alert>}
          </div>
          <Box />
          <FormControl className={classes.formControl}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={props.accounts && props.accounts.map((account) => (
                `${account.name}, ${account.city}`
              ))}
              renderInput={(params) => <TextField {...params} label="Accounts" />}
              onChange={(event: any, newValue: string | null) => {
                if(newValue !== null) {
                  var accountName = newValue.split(',')[0]
                  setAccountName(accountName);
                } else {
                  setAccountName('');
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={props.mills && props.mills.map((mill) => (
                mill.name
              ))}
              renderInput={(params) => <TextField {...params} label="Mill name" />}
              onChange={(event: any, newValue: string | null) => {
                if(newValue !== null) {
                  setMillName(newValue);
                } else {
                  setMillName('');
                }
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="quantity" inputRef={quantityRef} label="Quantity" defaultValue={props.bargain && props.bargain.quantity_in_quintals} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog-purchase-date"
                label="Purchase Date"
                format="dd/MM/yyyy"
                value={purchaseDate}
                onChange={handlePurchaseDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog-due-date"
                label="Due date"
                format="dd/MM/yyyy"
                value={dueDate}
                onChange={handleDueDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="late_lifting_penalty_per_quintal" inputRef={lateLiftingPenaltyRef} label="Late lifting penalty (per quintal)" defaultValue={props.bargain && props.bargain.late_lifting_penalty_per_quintal} />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
