import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { getNameFromId, getIdFromName } from '../../utils'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormatDate from '../../date_formatter'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface OverrideDateDialogProps {
  open
  onClose
  dialogTitle

  handleSubmit
};

export default function OverrideDateDialog(props: OverrideDateDialogProps) {
  const classes = useStyles();

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // If it is now open, but was previously not open
    if (open && !prevVisibility) {
      // Reset items here
      setShowAlert(false);
    }
  }, [open, prevVisibility]);

  const [showAlert, setShowAlert] = useState(false);
  const [date, setDate] = React.useState<Date | null>(
    new Date(),
  );

  const handleDate = (date: Date | null) => {
    setShowAlert(false);
    setDate(date);
  };

  const handleSubmit = () => {
    if(FormatDate(date) === '') {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      props.handleSubmit(constructResponseMap());
    }
  };

  const constructResponseMap = () => {
    var temp = new Map<string, Date | null>();
    temp.set('date', date);
    return temp;
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">Please select a date or click cancel</Alert>}
          </div>
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog-sale-date"
                label="Sale Date"
                format="dd/MM/yyyy"
                value={date}
                onChange={handleDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
