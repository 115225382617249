import React, { useState, useRef, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { FormatPrettyDate } from '../../date_formatter'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Hastakshar from './hastakshar';
import OverrideDeliveryOrderNumberDialog from './override_delivery_order_number_dialog';
import OverrideDateDialog from './override_date_dialog';
import TcsOrTdsDialog from './tcs_or_tds_dialog';
import { formatAmount } from '../../utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      flexGrow: 1,
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    table: {
      minWidth: 650,
      border: '1px solid',
    },
    tableCell: {
      border: '1px solid',
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface DeliveryOrderProps {
  open
  deliveryOrder
  mill
  account
  bill_to_party
  ship_to_party
  onClose
};

export default function DeliveryOrder(props: DeliveryOrderProps) {
  const classes = useStyles();

  const [tcsOrTds, setTcsOrTds] = React.useState('tcs');
  const [amount, setAmount] = React.useState(null);
  const [insurance, setInsurance] = React.useState(null);
  const [gst, setGst] = React.useState(null);
  const [tcsOrTdsAmount, setTcsOrTdsAmount] = React.useState(null);
  const [totalAmount, setTotalAmount] = React.useState(null);
  const [overriddenDeliveryOrderNumber, setOverriddenDeliveryOrderNumber] = React.useState(null);
  const [openOverrideDeliveryOrderNumberDialog, setOpenOverrideDeliveryOrderNumberDialog] = React.useState(false);
  const [openTcsOrTdsDialog, setOpenTcsOrTdsDialog] = React.useState(false);

  const [overriddenDate, setOverriddenDate] = React.useState<Date | null>(
    new Date(),
  );
  const [openOverrideDateDialog, setOpenOverrideDateDialog] = React.useState(false);

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // Reset items here
    var tempAmount = +props.deliveryOrder.quantity_in_quintals * +props.deliveryOrder.overridden_delivery_order_rate;
    var tempInsurance = 0;
    var tempTcsOrTdsAmount = 0;

    if(props.mill.insurance_type === 'percentage') {
      tempInsurance = +(+props.mill.insurance_value * tempAmount)/100.0;
    } else if(props.mill.insurance_type === 'per_quintal') {
      tempInsurance = +props.mill.insurance_value * +props.deliveryOrder.quantity_in_quintals;
    } else {
      console.log('undefined mill.insurance_type')
    }
    var tempGst = +(0.05 * (tempAmount + tempInsurance)).toFixed(2);

    if(tcsOrTds === 'tcs') {
      tempTcsOrTdsAmount = +(0.001 * (tempAmount + tempInsurance + tempGst)).toFixed(2);
    } else if(tcsOrTds === 'tds') {
      tempTcsOrTdsAmount = -1.0 * +(0.001 * (tempAmount + tempInsurance)).toFixed(2);
    }

    var tempTotalAmount = tempAmount + tempInsurance + tempGst + tempTcsOrTdsAmount;
    setAmount(tempAmount);
    setGst(tempGst);
    setTcsOrTdsAmount(tempTcsOrTdsAmount);
    setInsurance(tempInsurance);
    setTotalAmount(tempTotalAmount);
  }, [open, prevVisibility, tcsOrTds]);

  const plotTable =() => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell width="30%" className={classes.tableCell}>BILL TO</TableCell>
              <TableCell width="30%" className={classes.tableCell} align="left">SHIP TO</TableCell>
              <TableCell width="13%" className={classes.tableCell} align="left">QUANTITY</TableCell>
              <TableCell width="13%" className={classes.tableCell} align="left">QUALITY</TableCell>
              <TableCell width="13%" className={classes.tableCell} align="left">RATE PER QTL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={props.deliveryOrder.id}>
              <TableCell width="30%" className={classes.tableCell} component="th" scope="row">
                {props.bill_to_party.name}
                <br />
                {props.bill_to_party.address}
                <br />
                Phone: {props.bill_to_party.phone}
                <br />
                Email: {props.bill_to_party.email}
                <br />
                GST No.: <b>{props.bill_to_party.gst_details}</b>
              </TableCell>
              <TableCell width="30%" className={classes.tableCell} align="left">
                {props.ship_to_party.name}
                <br />
                {props.ship_to_party.address}
                <br />
                Phone: {props.ship_to_party.phone}
                <br />
                Email: {props.ship_to_party.email}
                <br />
                GST No.: <b>{props.ship_to_party.gst_details}</b>
              </TableCell>
              <TableCell width="13%" className={classes.tableCell} align="left">
                {props.deliveryOrder && props.deliveryOrder.quantity_in_quintals} QNTL
                <br />
                ({props.deliveryOrder && +props.deliveryOrder.quantity_in_quintals * 2} KATTA)
              </TableCell>
              <TableCell width="13%" className={classes.tableCell} align="left">
                {props.deliveryOrder && props.deliveryOrder.item}
                <br/>
                {props.deliveryOrder && props.deliveryOrder.season}
                <br/>
                {props.deliveryOrder && props.deliveryOrder.quality}
                <br/>
                {props.deliveryOrder && props.deliveryOrder.plain_or_refined}
              </TableCell>
              <TableCell width="13%" className={classes.tableCell} align="left">
                {props.deliveryOrder && props.deliveryOrder.overridden_delivery_order_rate && formatAmount(props.deliveryOrder.overridden_delivery_order_rate)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  };

  const insuranceDescription = () => {
    if(props.mill.insurance_type === 'percentage') {
      return props.mill.insurance_value + "%";
    } else if(props.mill.insurance_type === 'per_quintal') {
      return "Rs." + props.mill.insurance_value + " per quintal"
    } else {
      console.log('undefined Mill insurance type in DeliveryOrder')
    }
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <Box component="span" displayPrint="none">
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                D.O. #{props.deliveryOrder.id}
              </Typography>
              <Button variant="outlined" color="inherit" size="large" onClick={() => setOpenTcsOrTdsDialog(true)}>
                Set TCS or TDS
              </Button>
              <Button variant="outlined" color="inherit" size="large" onClick={() => setOpenOverrideDeliveryOrderNumberDialog(true)}>
                Override D.O. Number
              </Button>
              <Button variant="outlined" color="inherit" size="large" onClick={() => setOpenOverrideDateDialog(true)}>
                Override Date
              </Button>
              <Button variant="outlined" color="inherit" size="large" onClick={()=> window.open(`/delivery_orders/${props.deliveryOrder.id}.pdf`, '_blank')}>
                Print
              </Button>
              <TcsOrTdsDialog
                open={openTcsOrTdsDialog}
                onClose={() => setOpenTcsOrTdsDialog(false)}
                dialogTitle="Set TCS or TDS"
                handleSubmit={(response) => setTcsOrTds(response.get('tcs_or_tds')) }
              />
              <OverrideDeliveryOrderNumberDialog
                open={openOverrideDeliveryOrderNumberDialog}
                onClose={() => setOpenOverrideDeliveryOrderNumberDialog(false)}
                dialogTitle="Override D.O. Number"
                handleSubmit={(response) => setOverriddenDeliveryOrderNumber(response.get('delivery_order_number'))}
              />
              <OverrideDateDialog
                open={openOverrideDateDialog}
                onClose={() => setOpenOverrideDateDialog(false)}
                dialogTitle="Override Date"
                handleSubmit={(response) => {
                  setOverriddenDate(response.get('date'));
                  setOpenOverrideDateDialog(false);
                }}
              />
            </Toolbar>
          </AppBar>
        </Box>
        <Container>
          {/* <div className={classes.root}>
            <Button variant="contained" color="primary">Email to Mill</Button>
            <Button variant="outlined" color="primary">Email to Party ({props.party.name})</Button>
            <Button variant="outlined" color="primary">SMS to Party ({props.party.name})</Button>
          </div> */}
          <Typography component="div" align="center" gutterBottom>
            <Box mt={1} fontSize={20}>Delivery Order / Loading Details</Box>
            <Box fontSize={30} color="red" fontWeight="fontWeightBold">GOYAL SUGARS</Box>
            <Box fontSize={16}>(Sugar Selling Agent)</Box>
            <Box fontSize={16} color="green" fontWeight="fontWeightBold">
              7/97 Kaiser Ganj Mandi, Delhi Road, Meerut
              <br />
              Email: gngmeerut@gmail.com, goyalsugars@gmail.com
              <br />
              GST No.: 09AAQPG9732K1Z1, FSSAI No.: 12716051000366
            </Box>
          </Typography>

          <Box m={2} />
          <Grid container spacing={2} justify="space-between">
            <Grid item sm={3}>
              <Typography variant="body1">
                To:
                <br />
                {props.mill.full_name}
                <br />
                {props.mill.address}
              </Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="body1">
                D.O. No.: {(overriddenDeliveryOrderNumber === null || overriddenDeliveryOrderNumber === '') ? props.deliveryOrder.id : overriddenDeliveryOrderNumber}
                <br />
                Date: {(overriddenDate === null) ? FormatPrettyDate(new Date()) : FormatPrettyDate(overriddenDate)}
              </Typography>
            </Grid>
          </Grid>
          <Box m={2} />
          <Typography variant="body1">
            Dear Sir/Madam,
            <br/>
            On presentation of this letter, please dispatch the following sugar against
            full payment as per details given below, under advise to us as usual,
            give our advise no. and date.
          </Typography>
          {plotTable()}
          <Box m={2} />
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={5}>
              <Typography variant="body1">
                Truck No.: {props.deliveryOrder.truck_number}
                <br />
                Remark: {props.deliveryOrder.remark}
                <br />
                <br />
                A/C: {props.account.name}
              </Typography>
            </Grid>
            <Grid item sm={1} />
            <Grid item xs={5}>
              <Box fontSize={16}>
                Amount: {amount && formatAmount(amount)}
                <br />
                Insurance ({insuranceDescription()}): {insurance && formatAmount(insurance)}
                <br />
                GST (5%): {gst && formatAmount(gst)}
                <br />
                {tcsOrTds === 'tcs' &&
                  <React.Fragment>
                    TCS (0.1%): {tcsOrTdsAmount && formatAmount(tcsOrTdsAmount)}
                    <br />
                    TDS (0.0%): 0
                  </React.Fragment>
                }
                {tcsOrTds === 'tds' &&
                  <React.Fragment>
                    TCS (0.0%): 0
                    <br />
                    TDS (-0.1%): {tcsOrTdsAmount && formatAmount(tcsOrTdsAmount)}
                  </React.Fragment>
                }
                {tcsOrTds === 'none' &&
                  <React.Fragment>
                    TCS (0.0%): 0
                    <br />
                    TDS (0.0%): 0
                  </React.Fragment>
                }
                <br />
              </Box>
              <Typography variant="h6">Total amount: {totalAmount && formatAmount(totalAmount)}</Typography>
              <br />
            </Grid>
            <Grid item sm={1} />
          </Grid>
          <Box m={1} />
          <Typography variant="body1">
            In case of any issues, please contact Goyal Sugars, Mobile: 9837075422
          </Typography>
          <Hastakshar />
          <Box fontSize={16}>For GOYAL SUGARS</Box>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
