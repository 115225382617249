import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PriceDialog from '../price_dialog'
import FormatDate from '../../date_formatter'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';

import BargainsDialog from '../bargains_dialog'
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../../utils'
import BargainPriceCard from '../bargain_price_card/bargain_price_card';
import SellOrderDialog from '../sell_order_dialog';

interface SimpleMenuForBargainPricesProps {
  bargainPrice
  bargain
  parties
  accounts
  items
  qualities
  seasons
  handleSubmitEditPrice
  handleCreateSellOrder
  handleDeletePrice
};

export default function SimpleMenuForBargainPrices(props: SimpleMenuForBargainPricesProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSellOrderDialog, setOpenSellOrderDialog] = React.useState(false);
  const [openEditBargainPriceDialog, setOpenEditBargainPriceDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSellOrderDialog = () => {
    setOpenSellOrderDialog(true);
    handleClose();
  };

  const handleOpenEditBargainPriceDialog = () => {
    setOpenEditBargainPriceDialog(true);
    handleClose();
  };
  
  const closeDialogs = () => {
    setOpenSellOrderDialog(false);
    setOpenEditBargainPriceDialog(false);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenSellOrderDialog}>Create Sell Order</MenuItem>
        <MenuItem onClick={handleOpenEditBargainPriceDialog}>Edit</MenuItem>
        <MenuItem onClick={() => props.handleDeletePrice(props.bargainPrice.id)}>Delete</MenuItem>
      </Menu>
      <SellOrderDialog 
        open={openSellOrderDialog}
        onClose={closeDialogs}
        dialogTitle={"Create a New Sell Order"}
        handleSubmit={props.handleCreateSellOrder} 
        bargain_price={props.bargainPrice}
        bargain={props.bargain}
        parties={props.parties}
        accounts={props.accounts}
        action="create"
      />
      <PriceDialog 
        open={openEditBargainPriceDialog}
        onClose={closeDialogs}
        dialogTitle="Edit Price"
        handleSubmit={props.handleSubmitEditPrice} 
        bargain_id={props.bargain.id}
        bargain_price={props.bargainPrice}
        items={props.items}
        qualities={props.qualities}
        seasons={props.seasons}
      />
    </React.Fragment>
  );
};
