import React, { useState, useEffect, useRef }  from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface PartiesOrMillsDialogProps {
  open
  onClose
  party_or_mill?
  handleSubmit
  modelName
  dialogTitle
  action
  checkGstAlreadyExists
  gstNumbers?
};

export default function PartiesOrMillsDialog(props: PartiesOrMillsDialogProps) {
  const classes = useStyles();

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // If it is now open, but was previously not open
    if (open && !prevVisibility) {
      // Reset items here
      props.party_or_mill && props.party_or_mill.id && setId(props.party_or_mill.id);
      props.party_or_mill && props.party_or_mill.name && setName(props.party_or_mill.name);
      props.party_or_mill && props.party_or_mill.full_name && setFullName(props.party_or_mill.full_name);
      props.party_or_mill && props.party_or_mill.address && setAddress(props.party_or_mill.address);
      props.party_or_mill && props.party_or_mill.city && setCity(props.party_or_mill.city);
      props.party_or_mill && props.party_or_mill.bank_account_details && setBank(props.party_or_mill.bank_account_details);
      props.party_or_mill && props.party_or_mill.gst_details && setGst(props.party_or_mill.gst_details);
      props.party_or_mill && props.party_or_mill.pin_code && setPinCode(props.party_or_mill.pin_code);
      props.party_or_mill && props.party_or_mill.email && setEmail(props.party_or_mill.email);
      props.party_or_mill && props.party_or_mill.phone && setPhone(props.party_or_mill.phone);
      props.party_or_mill && props.party_or_mill.tcs_or_tds && setTcsOrTds(props.party_or_mill.tcs_or_tds);
      props.party_or_mill && props.party_or_mill.insurance_type && setInsuranceType(props.party_or_mill.insurance_type);
      props.party_or_mill && props.party_or_mill.insurance_value && setInsuranceValue(props.party_or_mill.insurance_value);
      setShowAlert(false);
    }
  }, [open, prevVisibility]);

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');

  const [id, setId] = React.useState('');
  const [name, setName] = React.useState('');
  const [fullName, setFullName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [bank, setBank] = React.useState('');
  const [gst, setGst] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [tcsOrTds, setTcsOrTds] = React.useState('');
  const [pinCode, setPinCode] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [insuranceType, setInsuranceType] = React.useState('percentage');
  const [insuranceValue, setInsuranceValue] = React.useState('');
  const [checkedAddMillAsAccount, setCheckedAddMillAsAccount] = React.useState(false);

  const [didMount, setDidMount] = useState(false);
  const [pingGstApi, setPingGstApi] = React.useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  useEffect(() => {
    if(didMount) {
      axios.post(`https://appyflow.in/api/verifyGST?gstNo=${gst}&key_secret=4RN7O8kjMad69rFcurzb7UQLuwK2`)
      .then(response => {
        processGstApiCallResponse(response.data);
      })
      setPingGstApi(false);
    }
  }, [pingGstApi]);

  const processGstApiCallResponse = (data) => {
    console.log('processGstApiCallResponse', data);
    if(data.error === true) {
      setShowAlert(true);
      setAlertMessage('Error retrieving info using GST number');
    } else {
      setShowAlert(false);
      setName(data.taxpayerInfo.tradeNam);
      var addr = data.taxpayerInfo.pradr.addr;
      var [address, pinCode, city] = parseApiCallResponse(addr);
      setAddress(address);
      setPinCode(pinCode);
      setCity(city);
    }
  };

  const parseApiCallResponse = (addr) => {
    var result = "";
    if(addr.bno   !== '' && addr.bno  !== undefined) result += addr.bno   + ", "
    if(addr.flno  !== '' && addr.flno !== undefined) result += addr.flno  + ", "
    if(addr.lg    !== '' && addr.lg   !== undefined) result += addr.lg    + ", "
    if(addr.st    !== '' && addr.st   !== undefined) result += addr.st    + ", "
    if(addr.bnm   !== '' && addr.bnm  !== undefined) result += addr.bnm   + ", "
    if(addr.loc   !== '' && addr.loc  !== undefined) result += addr.loc   + ", "
    if(addr.city  !== '' && addr.city !== undefined) result += addr.city  + ", "
    if(addr.dst   !== '' && addr.dst  !== undefined) result += addr.dst   + ", "
    if(addr.stcd  !== '' && addr.stcd !== undefined) result += addr.stcd  + "- "
    if(addr.pncd  !== '' && addr.pncd !== undefined) result += addr.pncd
    return [result, addr.pncd, addr.dst];
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleFullName = (event) => {
    setFullName(event.target.value);
  };

  const handleAddress = (event) => {
    setAddress(event.target.value);
  };

  const handleCity = (event) => {
    setCity(event.target.value);
  };

  const handleBank = (event) => {
    setBank(event.target.value);
  };

  const handleGst = (event) => {
    if(props.checkGstAlreadyExists) {
      if(props.gstNumbers !== undefined) {
        if(props.gstNumbers.includes(event.target.value)) {
          alert('This GST number already exists')
        }
      }
    }
    setGst(event.target.value);
  };

  const handlePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePinCode = (event) => {
    setPinCode(event.target.value);
  };

  const handleInsuranceType = (event) => {
    setInsuranceType(event.target.value);
  };

  const handleInsuranceValue = (event) => {
    setInsuranceValue(event.target.value);
  };

  const handleClickGstSearch = (event) => {
    setPingGstApi(true);
  };

  const handleSubmit = () => {
    props.handleSubmit(constructResponseMap());
    props.onClose();
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('id', id);
    temp.set('name', name);
    temp.set('full_name', fullName);
    temp.set('address', address);
    temp.set('city', city);
    temp.set('bank_account_details', bank);
    temp.set('gst_details', gst);
    temp.set('phone', phone);
    temp.set('tcs_or_tds', tcsOrTds);
    temp.set('pin_code', pinCode);
    temp.set('email', email);
    temp.set('insurance_type', insuranceType);
    temp.set('insurance_value', insuranceValue);
    temp.set('checked_add_mill_as_account', String(checkedAddMillAsAccount));
    return temp;
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">{alertMessage}</Alert>}
          </div>
          <Box />
          <FormControl className={classes.formControl}>
            <Grid container spacing={2}>
              <Grid item>
                <TextField id="gst"   label="Gst Number" value={gst} onChange={handleGst} />
              </Grid>
              <Grid item>
                <IconButton aria-label="search-gst" onClick={handleClickGstSearch}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="name"      label="Name"             value={name}                  onChange={handleName} />
          </FormControl>
          {props.modelName === 'mill' &&
            <FormControl className={classes.formControl}>
              <TextField id="full_name"      label="Full Name"             value={fullName}                  onChange={handleFullName} />
            </FormControl>
          }
          <FormControl className={classes.formControl}>
            <TextField id="address"   label="Address"              value={address}               onChange={handleAddress} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="city"      label="City"                 value={city}                  onChange={handleCity} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="bank"      label="Bank Account Details" value={bank}  onChange={handleBank} />
          </FormControl>
          {props.modelName && (props.modelName === 'party' || props.modelName === 'account') &&
            <FormControl className={classes.formControl}>
              <TextField id="phone"     label="Phone"   value={phone}   onChange={handlePhone} />
            </FormControl>
          }
          <FormControl className={classes.formControl}>
            <TextField id="email"     label="Email"   value={email}   onChange={handleEmail} aria-describedby="email-helper-text"/>
            <FormHelperText id="email-helper-text">Enter as a comma-separated list. Example: first@gmail.com, second@gmail.com</FormHelperText>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="pin_code"  label="Pin Code"             value={pinCode}              onChange={handlePinCode} />
          </FormControl>
          {props.modelName && props.modelName === 'mill' &&
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Insurance Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={insuranceType}
                onChange={handleInsuranceType}
              >
                <MenuItem value="percentage">Percentage</MenuItem>
                <MenuItem value="per_quintal">Per quintal</MenuItem>
              </Select>
              <TextField id="insurance_value" aria-describedby="insurance-value-helper-text" label="Insurance Value" value={insuranceValue} onChange={handleInsuranceValue} />
              <FormHelperText id="insurance-value-helper-text">Enter 5 if it is 5%; or 10 if it is Rs.10 per quintal)</FormHelperText>
            </FormControl>
          }
          {props.modelName && props.modelName === 'mill' && props.action === 'create' &&
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedAddMillAsAccount}
                    onChange={() => setCheckedAddMillAsAccount(!checkedAddMillAsAccount)}
                    name="checkboxAddMillAsAccount"
                  />
                }
                label="Also add as an Account"
              />
            </FormControl>
          }
          <br />
          {props.modelName && props.modelName === 'party' &&
            <FormControl className={classes.formControl}>
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <Button variant={tcsOrTds === 'tcs' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('tcs')}>TCS</Button>
                <Button variant={tcsOrTds === 'tds' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('tds')}>TDS</Button>
              </ButtonGroup>
            </FormControl>
          }
          <Box />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
