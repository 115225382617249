
import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import PriceDialog from '../price_dialog'
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../../utils';
import SimpleMenuForBargainPrices from './simple_menu_for_bargain_price_card';

interface BargainPriceCardProps {
  handleSubmitCreatePrice
  bargain
  parties
  accounts
  items
  qualities
  seasons
  filteredBargainPrices
  handleCreateSellOrder
  handleSubmitEditPrice
  handleDeletePrice
};

export default function BargainPriceCard(props: BargainPriceCardProps) {
  // Props:
    // bargain
    // filteredBargainPrices

  const [openCreateBargainPriceDialog, setOpenCreateBargainPriceDialog] = React.useState(false);
  
  const handleOpenCreateBargainPriceDialog = () => {
    setOpenCreateBargainPriceDialog(true);
  };
  
  const closeDialog = () => {
    setOpenCreateBargainPriceDialog(false);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom component="div" align="center">
        Prices for different items
      </Typography>
      <Button 
        variant='contained'
        color='primary'
        size='small'
        onClick={handleOpenCreateBargainPriceDialog}
      >
        Add Price
      </Button>
      <PriceDialog 
        open={openCreateBargainPriceDialog}
        onClose={closeDialog}
        dialogTitle="Create Price"
        handleSubmit={props.handleSubmitCreatePrice} 
        bargain_id={props.bargain.id}
        items={props.items}
        qualities={props.qualities}
        seasons={props.seasons}
      />
      <Table size="small" aria-label="prices">
        <TableHead>
          <TableRow>
            <TableCell>B.P. ID</TableCell>
            <TableCell align="left">Rates</TableCell>
            <TableCell align="left">Details</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.filteredBargainPrices && props.filteredBargainPrices.map((bargainPrice) => (
            <TableRow key={bargainPrice.id}>
              <TableCell component="th" scope="row">{bargainPrice.id}</TableCell>
              <TableCell align="left">
                <div>Bargain rate: {bargainPrice.bargain_rate}</div>
                <div>D.O. rate: {bargainPrice.delivery_order_rate}</div>
              </TableCell>
              <TableCell align="left">
                <div>{bargainPrice.item}</div>
                <div>{bargainPrice.quality}</div>
                <div>{bargainPrice.season}</div>
                <div>{bargainPrice.plain_or_refined}</div>
              </TableCell>
              <TableCell align="left">
                <SimpleMenuForBargainPrices 
                  handleCreateSellOrder={props.handleCreateSellOrder}
                  bargainPrice={bargainPrice}
                  bargain={props.bargain}
                  parties={props.parties}
                  accounts={props.accounts}
                  handleSubmitEditPrice={props.handleSubmitEditPrice}
                  handleDeletePrice={props.handleDeletePrice}
                  items={props.items}
                  qualities={props.qualities}
                  seasons={props.seasons}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
};