import React from "react";
import ReactDOM from "react-dom";
import PermanentDrawerLeft from "./permanent_drawer";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <Auth0Provider
    domain="goyalsugars.us.auth0.com"
    clientId="tqcvmRRERFC5ZSjT8adxtlkMxHRV4yfX"
    redirectUri={window.location.origin}
  >
    <PermanentDrawerLeft />
  </Auth0Provider>,
  document.getElementById("root")
);
