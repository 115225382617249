import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PriceDialog from './price_dialog'
import FormatDate from '../date_formatter'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';

import BargainsDialog from './bargains_dialog'
import SellOrderDialog from './sell_order_dialog'
import DeliveryOrder from './delivery_order/delivery_order'
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../utils'
import SellOrderCard from './sell_order_card/sell_order_card';
import BargainPriceCard from './bargain_price_card/bargain_price_card';

interface DashboardRowProps {
  bargain
  parties
  accounts
  mills
  handleSubmitEditBargain
  handleDelete
  filteredBargainPrices
  filteredSellOrders
  items
  qualities
  seasons
  bargainPrices
  bargains
  handleSubmitCreatePrice
  handleSubmitEditPrice
  handleDeletePrice
  handleCreateSellOrder
  handleEditSellOrder
  handleDeleteSellOrder
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    width: '100%',
  },
  paperRoot: {
    backgroundColor: '#fafafa'
  }
}));

export default function DashboardRow(props: DashboardRowProps) {
  const bargain = props.bargain;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  var totalQuintalsSold = 0;
  props.filteredSellOrders.forEach(function (sellOrder) {
    totalQuintalsSold += +sellOrder.quantity_in_quintals
  });
  
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{bargain.id}</TableCell>
        <TableCell align="left">{getNameFromId(props.accounts, bargain.account_id)}</TableCell>
        <TableCell align="left">{getNameFromId(props.mills, bargain.mill_id)}</TableCell>
        <TableCell align="left">
          {bargain.quantity_in_quintals}
          <br />
          Remaining: {+bargain.quantity_in_quintals - totalQuintalsSold}
        </TableCell>
        <TableCell align="left">
          Purchased on {FormatDate(bargain.purchase_date)}
          <br />
          Due on {FormatDate(bargain.due_date)}
          <br />
          Late lifting Penalty: {bargain.late_lifting_penalty_per_quintal}
        </TableCell>
        <TableCell align="left">
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <BargainsDialog 
              buttonText="Edit" 
              buttonColor="primary" 
              buttonVariant="text" 
              dialogTitle="Edit an existing bargain"
              handleSubmit={props.handleSubmitEditBargain} 
              bargain={bargain}
              accounts={props.accounts}
              mills={props.mills}
              action="edit"
            />
            <Button 
              variant='text'
              color='secondary'
              onClick={() => props.handleDelete(bargain.id)}
              startIcon={<DeleteIcon />}
            >
              DELETE
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Grid container spacing={1} >
                <Grid item xs={10}>
                  <Paper className={classes.paperRoot} elevation={6}>
                    <Box m={1} p={1}>
                      <BargainPriceCard 
                        bargain={bargain}
                        filteredBargainPrices={props.filteredBargainPrices} 
                        handleSubmitCreatePrice={props.handleSubmitCreatePrice} 
                        handleSubmitEditPrice={props.handleSubmitEditPrice} 
                        handleDeletePrice={props.handleDeletePrice}
                        handleCreateSellOrder={props.handleCreateSellOrder}
                        items={props.items}
                        qualities={props.qualities}
                        seasons={props.seasons}
                        parties={props.parties}
                        accounts={props.accounts}
                      />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={10}>
                  <Paper className={classes.paperRoot} elevation={6}>
                    <Box m={1} p={1}>
                      <SellOrderCard 
                        filteredSellOrders={props.filteredSellOrders}
                        parties={props.parties}
                        accounts={props.accounts}
                        filteredBargainPrices={props.filteredBargainPrices}
                        bargainPrices={props.bargainPrices}
                        bargains={props.bargains}
                        mills={props.mills}
                        handleCreateSellOrder={props.handleCreateSellOrder}
                        handleEditSellOrder={props.handleEditSellOrder}
                        handleDeleteSellOrder={props.handleDeleteSellOrder}
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};