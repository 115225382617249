import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import DefaultDialog from './default_dialog'

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
}));

interface DefaultTableProps {
  tableName
  columns
  modelName
};

export default function DefaultTable(props: DefaultTableProps) {
  const classes = useStyles();
  
  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false)
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState(new Map<string, string>());
  const [editedItem, setEditedItem] = useState(new Map<string, string>());
  const [toDeleteItemId, setToDeleteItemId] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  useEffect(() => {
    axios.get(`/${props.tableName}.json`)
      .then(response => {
        const data = response.data
        setItems(data)
     })

      setRefreshPage(false);
  }, [refreshPage]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = { 
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const item = constructMap(editedItem);
      axios.put(`/${props.tableName}/${editedItem.get('id')}.json`, item, { headers })
      setRefreshPage(true);
    }
  }, [editedItem]); // Only re-run the effect if editedItem changes

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = { 
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const item = constructMap(newItem);
      axios.post(`/${props.tableName}.json`, item, { headers })
      setRefreshPage(true);
    }
  }, [newItem]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = { 
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/${props.tableName}/${toDeleteItemId}.json`, { headers })
      setRefreshPage(true);
    }
  }, [toDeleteItemId]); // Only re-run the effect if newItem changes

  const constructMap = (stateVariable) => {
    var arr = []
    props.columns.forEach(function (columnName) {
      var map = new Map<string, string>();
      map['key'] = columnName;
      map['val'] = stateVariable.get(columnName);
      arr.push(map);
    });
    
    var result = arr.reduce(function(map, obj) {
      map[obj.key] = obj.val;
      return map;
    }, {});

    return result;
  }

  const handleSubmitEditItem = (id, response) => {
    var temporaryMap = createTemporaryMap(response);
    temporaryMap.set('id', id);
    setEditedItem(temporaryMap);
  };

  const handleSubmitCreateItem = (_, response) => {
    var temporaryMap = createTemporaryMap(response)
    setNewItem(temporaryMap);
  };

  const createTemporaryMap = (response) => {
    var temporaryMap = new Map<string, string>();
    props.columns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    return temporaryMap;
  };

  const handleDelete = (item_id) => {
    setToDeleteItemId(item_id);
  };

  const createDialog = () => {
    return (
      <DefaultDialog 
        buttonText={"Create " + props.modelName}
        buttonColor="primary" 
        buttonVariant="contained" 
        dialogSubtitle={"Create a new "+ props.modelName}
        handleSubmit={handleSubmitCreateItem} 
      />
    )
  };

  const editDialog = (item) => {
    return (
      <DefaultDialog 
        buttonText="Edit" 
        buttonColor="primary" 
        buttonVariant="text" 
        dialogSubtitle={"Edit an existing "+ props.modelName}
        handleSubmit={handleSubmitEditItem} 
        id={item.id}
        value={item.value}
      />
    )
  };
    
  return (
    <Paper variant="outlined">
      {createDialog()}
      <Box m={1.5}/>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {props.columns && props.columns.map((columnName, i) => (
                <TableCell key={columnName + i}>{columnName}</TableCell>
              ))}
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items && items.map((item) => (
              <TableRow key={item.id}>
                {props.columns && props.columns.map((columnName, j) => (
                  <TableCell key={j + columnName} component="th" scope="row">{item[columnName]}</TableCell>
                ))}
                <TableCell align="left">
                  <ButtonGroup color="primary" aria-label="outlined primary button group">
                    {editDialog(item)}
                    <Button 
                      variant='text'
                      color='secondary'
                      onClick={() => handleDelete(item.id)}
                    >
                      DELETE
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
