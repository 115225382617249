import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import PartiesOrMillsDialog from './parties_or_mills_or_accounts_dialog';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';


const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
}));

interface MillsTableProps {
  millColumns
  accountColumns
  partyColumns
};

export default function MillsTable(props: MillsTableProps) {
  const classes = useStyles();

  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false)
  const [mills, setMills] = useState([]);
  const [gstNumbers, setGstNumbers] = useState([]);
  const [newMill, setNewMill] = useState(new Map<string, string>());
  const [newAccount, setNewAccount] = useState(new Map<string, string>());
  const [newParty, setNewParty] = useState(new Map<string, string>());
  const [editedMill, setEditedMill] = useState(new Map<string, string>());
  const [toDeleteMillId, setToDeleteMillId] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  useEffect(() => {
    axios.get('/mills.json')
      .then(response => {
        const data = response.data
        const gstNumbers = compileGstList(data)
        setGstNumbers(gstNumbers)
        setMills(data)
     })

      setRefreshPage(false);
  }, [refreshPage]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/mills/${editedMill['id']}.json`, editedMill, { headers })
           .then(response => {
              console.log('PUT mills, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [editedMill]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const account = constructMap(newMill, props.millColumns);
      axios.post(`/mills.json`, account, { headers })
           .then(response => {
              console.log('POST mills, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newMill]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const account = constructMap(newAccount, props.accountColumns);
      axios.post(`/accounts.json`, account, { headers })
           .then(response => {
              console.log('POST accounts, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newAccount]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const party = constructMap(newParty, props.partyColumns);
      axios.post(`/parties.json`, party, { headers })
           .then(response => {
              console.log('POST parties, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [newParty]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/mills/${toDeleteMillId}.json`, { headers })
           .then(response => {
              console.log('DELETE mills, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [toDeleteMillId]);

  const compileGstList = (mills) => {
    const gstNumbers = []
    mills.forEach(function (mill) {
      gstNumbers.push(mill.gst_details);
    });
    return gstNumbers;
  }

  const constructMap = (stateVariable, columns) => {
    var arr = []
    columns.forEach(function (columnName) {
      var map = new Map<string, string>();
      map['key'] = columnName;
      map['val'] = stateVariable.get(columnName);
      arr.push(map);
    });

    var result = arr.reduce(function(map, obj) {
      map[obj.key] = obj.val;
      return map;
    }, {});

    return result;
  }

  const handleEditMill = (response) => {
    var mill = mills.filter(mill => mill.id == response.get('id'))[0];
    props.millColumns.forEach(function (columnName) {
      mill[columnName] = response.get(columnName)
    });
    setEditedMill(mill)
  };

  const handleCreateAccount = (response) => {
    var temporaryMap = new Map<any, string>();
    props.accountColumns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewAccount(temporaryMap);
  };

  const handleCreateParty = (response) => {
    var temporaryMap = new Map<any, any>();
    props.partyColumns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewParty(temporaryMap);
  };

  const handleCreateMill = (response) => {
    var temporaryMap = new Map<any, any>();
    props.millColumns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewMill(temporaryMap);
  };

  const handleDeleteMill = (mill_id) => {
    setToDeleteMillId(mill_id);
  };

  const [openCreateMillDialog, setOpenCreateMillDialog] = React.useState(false);

  const handleOpenCreateMillDialog = () => {
    setOpenCreateMillDialog(true);
  };

  const closeCreateMillDialog = () => {
    setOpenCreateMillDialog(false);
  };

  return (
    <Paper variant="outlined">
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleOpenCreateMillDialog}
      >
        Create Mill
      </Button>
      <PartiesOrMillsDialog
        open={openCreateMillDialog}
        onClose={closeCreateMillDialog}
        dialogTitle={"Create mill"}
        handleSubmit={handleCreateMill}
        modelName="mill"
        action="create"
        checkGstAlreadyExists={true}
        gstNumbers={gstNumbers}
      />
      <Box m={1.5}/>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {props.millColumns && props.millColumns.map((columnName, i) => (
                <TableCell key={columnName + i}>{columnName}</TableCell>
              ))}
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mills && mills.map((mill) => (
              <TableRow key={mill.id}>
                {props.millColumns && props.millColumns.map((columnName, j) => (
                  <TableCell key={j + columnName} component="th" scope="row">{mill[columnName]}</TableCell>
                ))}
                <TableCell align="left">
                  <SimpleMenuForMill
                    mill={mill}
                    handleEditMill={handleEditMill}
                    handleDeleteMill={handleDeleteMill}
                    handleCreateParty={handleCreateParty}
                    handleCreateAccount={handleCreateAccount}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
};

interface SimpleMenuForMillProps {
  mill
  handleEditMill
  handleDeleteMill
  handleCreateParty
  handleCreateAccount
};

function SimpleMenuForMill(props: SimpleMenuForMillProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openCopyToPartyDialog, setOpenCopyToPartyDialog] = React.useState(false);
  const [openCopyToAccountDialog, setOpenCopyToAccountDialog] = React.useState(false);
  const [openEditMillDialog, setOpenEditMillDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCopyToPartyDialog = () => {
    setOpenCopyToPartyDialog(true);
    handleClose();
  };

  const handleOpenCopyToAccountDialog = () => {
    setOpenCopyToAccountDialog(true);
    handleClose();
  };

  const handleOpenEditMillDialog = () => {
    setOpenEditMillDialog(true);
    handleClose();
  };

  const closeDialogs = () => {
    setOpenCopyToPartyDialog(false);
    setOpenCopyToAccountDialog(false);
    setOpenEditMillDialog(false);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenCopyToPartyDialog}>Create Party from this mill</MenuItem>
        <MenuItem onClick={handleOpenCopyToAccountDialog}>Create Account from this mill</MenuItem>
        <MenuItem onClick={handleOpenEditMillDialog}>Edit Mill</MenuItem>
        <MenuItem onClick={() => props.handleDeleteMill(props.mill.id)}>Delete</MenuItem>
      </Menu>
      <PartiesOrMillsDialog
        open={openCopyToPartyDialog}
        onClose={closeDialogs}
        dialogTitle={"Create party from mill"}
        handleSubmit={props.handleCreateParty}
        party_or_mill={props.mill}
        modelName="party"
        action="create"
        checkGstAlreadyExists={true}
      />
      <PartiesOrMillsDialog
        open={openCopyToAccountDialog}
        onClose={closeDialogs}
        dialogTitle={"Create account from mill"}
        handleSubmit={props.handleCreateAccount}
        party_or_mill={props.mill}
        modelName="account"
        action="create"
        checkGstAlreadyExists={true}
      />
      <PartiesOrMillsDialog
        open={openEditMillDialog}
        onClose={closeDialogs}
        dialogTitle={"Edit mill"}
        handleSubmit={props.handleEditMill}
        party_or_mill={props.mill}
        modelName="mill"
        action="edit"
        checkGstAlreadyExists={true}
      />
    </React.Fragment>
  );
};