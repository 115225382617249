import React from 'react';
import Box from '@material-ui/core/Box';
import DefaultTable from './default_table';
import MillsTable from './mills_table';
import AccountsTable from './accounts_table';
import PartiesTable from './parties_table';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function BasicModels() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Accounts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AccountsTable 
            accountColumns={['name', 'address', 'city', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'phone']} 
            partyColumns={['name', 'address', 'city', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'phone', 'tcs_or_tds']}
          />
        </AccordionDetails>
      </Accordion>
      
      <Box m={2}  />
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Mills</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MillsTable 
            millColumns={['name', 'full_name', 'address', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'insurance_type', 'insurance_value']} 
            accountColumns={['name', 'address', 'city', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'phone']} 
            partyColumns={['name', 'address', 'city', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'phone']}
          />
        </AccordionDetails>
      </Accordion>

      <Box m={2}  />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Parties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <PartiesTable 
            accountColumns={['name', 'address', 'city', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'phone']} 
            partyColumns={['name', 'address', 'city', 'gst_details', 'bank_account_details', 'pin_code', 'email', 'phone', 'tcs_or_tds']}
          />
        </AccordionDetails>
      </Accordion>
      
      <Box m={2}  />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Items</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DefaultTable modelName="item" tableName="items" columns={['value']} />
        </AccordionDetails>
      </Accordion>

      <Box m={2}  />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Qualities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DefaultTable modelName="quality" tableName="qualities" columns={['value']} />
        </AccordionDetails>
      </Accordion>

      <Box m={2}  />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Seasons</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DefaultTable modelName="season" tableName="seasons" columns={['value']} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
