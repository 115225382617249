import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import BargainsDialog from './bargains_dialog';
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../utils';
import DashboardRow from './dashboard_row';
import SellOrders from './sell_orders';
import DashboardTabs from './dashboard_tabs';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    width: '100%',
  },
  paperRoot: {
    backgroundColor: '#fafafa'
  }
}));

interface DashboardProps {
  tableName
  modelName
  columns
};

export default function Dashboard(props: DashboardProps) {
  const classes = useStyles();

  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false)
  const [bargains, setBargains] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [parties, setParties] = useState([]);
  const [mills, setMills] = useState([]);
  const [items, setItems] = useState([]);
  const [qualities, setQualities] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [bargainPrices, setBargainPrices] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);
  const [deliveryOrders, setDeliveryOrders] = useState([]);

  const [newItem, setNewItem] = useState(new Map<string, string>());
  const [editedItem, setEditedItem] = useState(new Map<string, string>());
  const [toDeleteItemId, setToDeleteItemId] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);

  const [newBargainPrice, setNewBargainPrice] = useState(new Map<string, string>());
  const [editedBargainPrice, setEditedBargainPrice] = useState(new Map<string, string>());
  const [toDeleteBargainPriceId, setToDeleteBargainPriceId] = useState('');

  const [newSellOrder, setNewSellOrder] = useState(new Map<string, string>());
  const [editedSellOrder, setEditedSellOrder] = useState(new Map<string, string>());
  const [toDeleteSellOrderId, setToDeleteSellOrderId] = useState('');

  const [newDeliveryOrder, setNewDeliveryOrder] = useState(new Map<string, string>());
  const [editedDeliveryOrder, setEditedDeliveryOrder] = useState(new Map<string, string>());
  const [toDeleteDeliveryOrderId, setToDeleteDeliveryOrderId] = useState('');

  const [showPurchases, setShowPurchases] = useState(true);
  const [showSellOrders, setShowSellOrders] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  useEffect(() => {
    axios.get(`/bargains.json`)
      .then(response => {
        const data = response.data
        console.log('bargains', response.data);
        setBargains(data)
     })

    axios.get("/accounts.json")
     .then(response => {
       const data = response.data
       setAccounts(data)
    })

    axios.get("/parties.json")
     .then(response => {
       const data = response.data
       setParties(data)
    })

    axios.get("/mills.json")
     .then(response => {
       const data = response.data
       setMills(data)
    })

    axios.get("/items.json")
     .then(response => {
       const data = response.data
       setItems(data)
    })

    axios.get("/qualities.json")
     .then(response => {
       const data = response.data
       setQualities(data)
    })

    axios.get("/seasons.json")
     .then(response => {
       const data = response.data
       setSeasons(data)
    })

    axios.get("/bargain_prices.json")
     .then(response => {
       const data = response.data
       console.log('bargain_prices', response.data);
       setBargainPrices(data)
    })

    axios.get("/sell_orders.json")
     .then(response => {
       const data = response.data
       setSellOrders(data)
    })

    axios.get("/delivery_orders.json")
     .then(response => {
       const data = response.data
       setDeliveryOrders(data)
    })

    setRefreshPage(false);
  }, [refreshPage]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/${props.tableName}/${editedItem['id']}.json`, editedItem, { headers })
      setRefreshPage(true);
    }
  }, [editedItem]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const item = constructMap(newItem);
      axios.post(`/${props.tableName}.json`, item, { headers })
      setRefreshPage(true);
    }
  }, [newItem]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/bargain_prices/${editedBargainPrice['id']}.json`, editedBargainPrice, { headers })
           .then(response => {
              console.log('PUT bargain_prices, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [editedBargainPrice]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const item = constructBargainPriceMap(newBargainPrice);
      axios.post(`/bargain_prices.json`, item, { headers })
           .then(response => {
             console.log('POST bargain_prices, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newBargainPrice]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const item = constructBargainPriceMap(newSellOrder);
      axios.post(`/sell_orders.json`, item, { headers })
           .then(response => {
             console.log('POST sell_orders, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newSellOrder]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/sell_orders/${editedSellOrder['id']}.json`, editedSellOrder, { headers })
           .then(response => {
             console.log('PUT sell_orders, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [editedSellOrder]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/sell_orders/${toDeleteSellOrderId}.json`, { headers })
           .then(response => {
              console.log('DELETE sell_orders, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [toDeleteSellOrderId]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const item = constructBargainPriceMap(newDeliveryOrder);
      axios.post(`/delivery_orders.json`, item, { headers })
           .then(response => {
             console.log('POST delivery_orders, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newDeliveryOrder]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/delivery_orders/${editedDeliveryOrder['id']}.json`, editedDeliveryOrder, { headers })
           .then(response => {
             console.log('PUT delivery_orders, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [editedDeliveryOrder]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/delivery_orders/${toDeleteDeliveryOrderId}.json`, { headers })
           .then(response => {
              console.log('DELETE delivery_orders, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [toDeleteDeliveryOrderId]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/bargain_prices/${toDeleteBargainPriceId}.json`, { headers })
      setRefreshPage(true);
    }
  }, [toDeleteBargainPriceId]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/${props.tableName}/${toDeleteItemId}.json`, { headers })
      setRefreshPage(true);
    }
  }, [toDeleteItemId]);

  const constructMap = (stateVariable) => {
    var arr = []
    props.columns.forEach(function (columnName) {
      var map = new Map<string, string>();
      map['key'] = columnName;
      map['val'] = stateVariable.get(columnName);
      arr.push(map);
    });

    var result = arr.reduce(function(map, obj) {
      map[obj.key] = obj.val;
      return map;
    }, {});

    return result;
  }

  const constructBargainPriceMap = (stateVariable) => {
    var arr = []
    var columns = Array.from(stateVariable.keys());
    columns.forEach(function (columnName) {
      var map = new Map<string, string>();
      map['key'] = columnName;
      map['val'] = stateVariable.get(columnName);
      arr.push(map);
    });

    var result = arr.reduce(function(map, obj) {
      map[obj.key] = obj.val;
      return map;
    }, {});

    return result;
  }

  const handleSubmitEditBargain = (response) => {
    var bargain = bargains.filter(bargain => bargain.id == response.get('bargain_id'))[0];
    bargain.account_id = response.get('account_id')
    bargain.mill_id = response.get('mill_id')
    bargain.quantity_in_quintals = response.get('quantity_in_quintals')
    bargain.late_lifting_penalty_per_quintal = response.get('late_lifting_penalty_per_quintal')
    bargain.purchase_date = response.get('purchase_date')
    bargain.due_date = response.get('due_date')
    setEditedItem(bargain)
  };

  const handleSubmitCreateItem = (response) => {
    console.log('in handleSubmitCreateItem', 'response', response)
    var temporaryMap = createTemporaryMap(response)
    setNewItem(temporaryMap);
  };

  const handleSubmitCreatePrice = (response) => {
    var columns = Array.from(response.keys());
    var temporaryMap = new Map<any, string>();
    columns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewBargainPrice(temporaryMap);
  };

  const handleSubmitEditPrice = (response) => {
    var bargain_price = bargainPrices.filter(bargain_price => bargain_price.id == response.get('bargain_price_id'))[0];
    bargain_price.bargain_id = response.get('bargain_id')
    bargain_price.bargain_rate = response.get('bargain_rate')
    bargain_price.delivery_order_rate = response.get('delivery_order_rate')
    bargain_price.item = response.get('item')
    bargain_price.quality = response.get('quality')
    bargain_price.season = response.get('season')
    bargain_price.plain_or_refined = response.get('plain_or_refined')
    setEditedBargainPrice(bargain_price)
  };

  const handleDeletePrice = (bargain_price_id) => {
    setToDeleteBargainPriceId(bargain_price_id);
  };

  const createTemporaryMap = (response) => {
    var temporaryMap = new Map<string, string>();
    props.columns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    return temporaryMap;
  };

  const handleCreateSellOrder = (response) => {
    console.log('in handleCreateSellOrder', 'response', response)
    var columns = Array.from(response.keys());
    var temporaryMap = new Map<any, string>();
    columns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });

    var bargain_price = bargainPrices.filter(bargain_price => bargain_price.id === response.get('bargain_price_id'))[0];
    var bargain_id = bargain_price.bargain_id;
    var bargain = bargains.filter(bargain => bargain.id === bargain_id)[0];
    var totalQuintalsSold = 0;
    filteredSellOrders(bargain_id).forEach(function (sellOrder) {
      totalQuintalsSold += +sellOrder.quantity_in_quintals
    });
    var remaining_quantity_before_new_sell_order = bargain.quantity_in_quintals - totalQuintalsSold;
    var remaining_quantity_after_new_sell_order = remaining_quantity_before_new_sell_order - response.get("quantity_in_quintals")
    if (remaining_quantity_after_new_sell_order < 0) {
      var m = `You only have ${remaining_quantity_before_new_sell_order} quintals remaining but you are trying to sell ${response.get("quantity_in_quintals")} quintals. Are you sure you want to proceed with this override?`;
      var r = confirm(m);
      if (r == true) {
        setNewSellOrder(temporaryMap);
      }
    } else {
        setNewSellOrder(temporaryMap);
    }
  };

  const handleEditSellOrder = (response) => {
    var sell_order = getElementFromId(sellOrders, response.get('sell_order_id'))
    sell_order.account_id = response.get('account_id')
    sell_order.quantity_in_quintals = response.get('quantity_in_quintals')
    sell_order.overridden_bargain_rate = response.get('overridden_bargain_rate')
    sell_order.overridden_delivery_order_rate = response.get('overridden_delivery_order_rate')
    sell_order.sale_date = response.get('sale_date')
    sell_order.due_date = response.get('due_date')

    setEditedSellOrder(sell_order)
  };

  const handleDeleteSellOrder = (sell_order_id) => {
    setToDeleteSellOrderId(sell_order_id);
  };

  const handleCreateDeliveryOrder = (response) => {
    console.log('in handleCreateDeliveryOrder', 'response', response)
    var columns = Array.from(response.keys());
    var temporaryMap = new Map<any, string>();
    columns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });

    var sell_order = sellOrders.filter(sell_order => sell_order.id == response.get('sell_order_id'))[0];
    var total_quantity_accounted_for = 0;
    filteredDeliveryOrders(response.get('sell_order_id')).forEach(function (deliveryOrder) {
      total_quantity_accounted_for += +deliveryOrder.quantity_in_quintals
    });
    var remaining_quantity_before_new_delivery_order = +sell_order.quantity_in_quintals - total_quantity_accounted_for;
    var remaining_quantity_after_new_delivery_order = remaining_quantity_before_new_delivery_order - response.get('quantity_in_quintals');

    if (remaining_quantity_after_new_delivery_order < 0) {
      var m = `You only have ${remaining_quantity_before_new_delivery_order} quintals remaining but you are trying to add a delivery order for ${response.get("quantity_in_quintals")} quintals. Are you sure you want to proceed with this override?`;
      var r = confirm(m);
      if (r == true) {
        setNewDeliveryOrder(temporaryMap);
      }
    } else {
      setNewDeliveryOrder(temporaryMap);
    }
  };

  const filteredDeliveryOrders = (sell_order_id) => {
    var result = deliveryOrders.filter(deliveryOrder => deliveryOrder.sell_order_id === sell_order_id);
    return result;
  };

  const handleEditDeliveryOrder = (response) => {
    var delivery_order = getElementFromId(deliveryOrders, response.get('delivery_order_id'))
    delivery_order.sell_order_id = response.get('sell_order_id')
    delivery_order.bill_to_id = response.get('bill_to_id')
    delivery_order.ship_to_id = response.get('ship_to_id')
    delivery_order.quantity_in_quintals = response.get('quantity_in_quintals')
    delivery_order.overridden_delivery_order_rate = response.get('overridden_delivery_order_rate')
    delivery_order.lifted = response.get('lifted')
    delivery_order.payment_resolved = response.get('payment_resolved')
    delivery_order.remark = response.get('remark')
    delivery_order.truck_number = response.get('truck_number')
    delivery_order.item = response.get('item')
    delivery_order.quality = response.get('quality')
    delivery_order.season = response.get('season')
    delivery_order.plain_or_refined = response.get('plain_or_refined')
    delivery_order.tcs_or_tds = response.get('tcs_or_tds')
    delivery_order.do_date = response.get('do_date')
    delivery_order.do_number = response.get('do_number')

    setEditedDeliveryOrder(delivery_order)
  };

  const handleDeleteDeliveryOrder = (delivery_order_id) => {
    setToDeleteDeliveryOrderId(delivery_order_id);
  };

  const handleToggleLiftedDeliveryOrder = (delivery_order_id) => {
    var delivery_order = getElementFromId(deliveryOrders, delivery_order_id)
    // Using if-else here to deal with lifted == null
    if(delivery_order.lifted === true) {
      delivery_order.lifted = false;
    } else {
      delivery_order.lifted = true;
    }

    setEditedDeliveryOrder(delivery_order)
  };

  const handleTogglePaymentResolvedDeliveryOrder = (delivery_order_id) => {
    var delivery_order = getElementFromId(deliveryOrders, delivery_order_id)
    // Using if-else here to deal with lifted == null
    if(delivery_order.payment_resolved === true) {
      delivery_order.payment_resolved = false;
    } else {
      delivery_order.payment_resolved = true;
    }

    setEditedDeliveryOrder(delivery_order)
  };

  const handleDelete = (item_id) => {
    setToDeleteItemId(item_id);
  };

  const createDialog = () => {
    return (
      <BargainsDialog
        buttonText="Create Purchase"
        dialogTitle="Create Purchase"
        buttonVariant="contained"
        buttonColor="default"
        handleSubmit={handleSubmitCreateItem}
        accounts={accounts}
        mills={mills}
        action="create"
      />
    )
  };

  const filteredBargainPrices = (bargain_id) => {
    var result = bargainPrices.filter(bargainPrice => bargainPrice.bargain_id === bargain_id);
    return result;
  };

  function filteredSellOrders (bargain_id) {
    var bargainPriceIds = extractColumn(filteredBargainPrices(bargain_id), 'id');
    var result = sellOrders.filter(sellOrder => bargainPriceIds.includes(sellOrder.bargain_price_id));
    return result;
  };

  const handleClickShowPurchases = () => {
    setShowPurchases(true);
    setShowSellOrders(false);
  };

  const handleClickShowSellOrders = () => {
    setShowPurchases(false);
    setShowSellOrders(true);
  };

  const handleTabClick = (newValue) => {
    if(newValue === 0) {
      handleClickShowPurchases();
    } else if(newValue === 1) {
      handleClickShowSellOrders();
    }
  }

  return (
    <React.Fragment>
      <DashboardTabs handleTabClick={handleTabClick} />
      <Box m={2} />
      {/* <Box mb={2}>
        <Button size="small" variant="contained" color={showPurchases ? 'primary' : 'default'} onClick={handleClickShowPurchases}>Purchases</Button>
        &nbsp;&nbsp;&nbsp;
        <Button size="small" variant="contained" color={showSellOrders ? 'primary' : 'default'} onClick={handleClickShowSellOrders}>Sell Orders</Button>
      </Box> */}
      { showPurchases &&
        <React.Fragment>
          <Box mb={2}>
            {createDialog()}
          </Box>
          <Paper variant="outlined">
            <Box m={1.5}/>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Bargain ID</TableCell>
                    <TableCell align="left">Account name</TableCell>
                    <TableCell align="left">Mill name</TableCell>
                    <TableCell align="left">Quantity</TableCell>
                    <TableCell align="left">Other info</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bargains.map((bargain) => (
                    <DashboardRow
                      key={bargain.id}
                      filteredBargainPrices={filteredBargainPrices(bargain.id)}
                      filteredSellOrders={filteredSellOrders(bargain.id)}
                      handleSubmitEditBargain={handleSubmitEditBargain}
                      handleDelete={handleDelete}
                      handleSubmitCreatePrice={handleSubmitCreatePrice}
                      handleSubmitEditPrice={handleSubmitEditPrice}
                      handleDeletePrice={handleDeletePrice}
                      handleCreateSellOrder={handleCreateSellOrder}
                      handleEditSellOrder={handleEditSellOrder}
                      handleDeleteSellOrder={handleDeleteSellOrder}
                      bargain={bargain}
                      accounts={accounts}
                      parties={parties}
                      mills={mills}
                      items={items}
                      qualities={qualities}
                      seasons={seasons}
                      bargains={bargains}
                      bargainPrices={bargainPrices}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </React.Fragment>
      }
      {showSellOrders &&
        <React.Fragment>
          <Box m={5} />
          <SellOrders
            modelName="sell_order"
            tableName="sell_orders"
            columns={['id','bargain_price_id','overridden_bargain_rate','overridden_delivery_order_rate','bill_to_id','ship_to_id','sale_date','due_date','lifted','payment_resolved','created_at','updated_at','remark','quantity_in_quintals','truck_number','account_id']}

            accounts={accounts}
            parties={parties}
            mills={mills}
            items={items}
            qualities={qualities}
            seasons={seasons}
            bargains={bargains}
            bargain_prices={bargainPrices}
            sell_orders={sellOrders}
            delivery_orders={deliveryOrders}

            handleCreateDeliveryOrder={handleCreateDeliveryOrder}
            handleEditDeliveryOrder={handleEditDeliveryOrder}
            handleDeleteDeliveryOrder={handleDeleteDeliveryOrder}

            handleToggleLiftedDeliveryOrder={handleToggleLiftedDeliveryOrder}
            handleTogglePaymentResolvedDeliveryOrder={handleTogglePaymentResolvedDeliveryOrder}

            handleEditSellOrder={handleEditSellOrder}
            handleDeleteSellOrder={handleDeleteSellOrder}
          />
        </React.Fragment>
      }
    </React.Fragment>
  )
};
