import React from "react";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function LoginPage() {
  const classes = useStyles();
  const { loginWithRedirect, logout } = useAuth0();

  return (
    <Grid container justify = "center">
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="280"
            image="https://image.shutterstock.com/image-photo/agriculture-sugarcane-field-farm-blue-600w-1233166594.jpg"
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Goyal Sugars
            </Typography>
          </CardContent>
        </CardActionArea>
        <Grid container justify = "center">
          <CardActions>
            <Button size="large" color="primary" variant="contained" disableElevation onClick={() => loginWithRedirect()}>
              Log in
            </Button>
            <Button onClick={() => logout({ returnTo: window.location.origin })}>
              Reset
            </Button>
          </CardActions>
        </Grid>
      </Card>
    </Grid>
  );
}
