import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { getNameFromId, getIdFromName } from '../utils'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormatDate from '../date_formatter'
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface SellOrderDialogProps {
  open
  bargain_price
  bargain
  action
  sell_order?
  parties
  accounts
  onClose
  dialogTitle
  handleSubmit
};

export default function SellOrderDialog(props: SellOrderDialogProps) {
  const classes = useStyles();

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // If it is now open, but was previously not open
    if (open && !prevVisibility) {
      // Reset items here
      props.bargain_price && props.bargain_price.id && setBargainPriceId(props.bargain_price.id);
      props.bargain && props.bargain.due_date && setDueDate(props.bargain.due_date);
      if(props.action === 'create') {
        setOverriddenBargainRate(props.bargain_price.bargain_rate);
        setOverriddenDeliveryOrderRate(props.bargain_price.delivery_order_rate);
      } else if(props.action === 'edit') {
        props.sell_order && props.sell_order.overridden_bargain_rate && setOverriddenBargainRate(props.sell_order.overridden_bargain_rate);
        props.sell_order && props.sell_order.overridden_delivery_order_rate && setOverriddenDeliveryOrderRate(props.sell_order.overridden_delivery_order_rate);
      }
      props.sell_order && props.sell_order.id && setSellOrderId(props.sell_order.id);
      props.sell_order && props.sell_order.quantity_in_quintals && setQuantity(props.sell_order.quantity_in_quintals);
      props.sell_order && props.sell_order.account_id && setAccountId(props.sell_order.account_id);
      props.parties && props.sell_order && props.sell_order.account_id && setAccountName(getNameFromId(props.accounts, props.sell_order.account_id));
      props.sell_order && props.sell_order.sale_date && setSaleDate(props.sell_order.sale_date);
      props.sell_order && props.sell_order.due_date && setDueDate(props.sell_order.due_date);
      setShowAlert(false);
    }
  }, [open, prevVisibility]);

  const [showAlert, setShowAlert] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [bargainPriceId, setBargainPriceId] = useState('');
  const [sellOrderId, setSellOrderId] = useState('');
  const [accountId, setAccountId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [overriddenBargainRate, setOverriddenBargainRate] = useState('');
  const [overriddenDeliveryOrderRate, setOverriddenDeliveryOrderRate] = useState('');
  const [accountName, setAccountName] = useState('');
  const [saleDate, setSaleDate] = React.useState<Date | null>(
    new Date(),
  );
  const [dueDate, setDueDate] = React.useState<Date | null>(
    new Date(),
  );

  const handleQuantity = (event) => {
    setQuantity(event.target.value);
  };
  const handleSaleDate = (date: Date | null) => {
    setSaleDate(date);
  };
  const handleDueDate = (date: Date | null) => {
    setDueDate(date);
  };
  const handleOverriddenBargainRate = (event) => {
    setOverriddenBargainRate(event.target.value);
  };
  const handleOverriddenDeliveryOrderRate = (event) => {
    setOverriddenDeliveryOrderRate(event.target.value);
  };

  const handleSubmit = () => {
    if( bargainPriceId === '' ||
        quantity === '' ||
        overriddenBargainRate === '' ||
        overriddenDeliveryOrderRate === '' ||
        accountId === '' ||
        FormatDate(saleDate) === '' ||
        FormatDate(dueDate) === ''
      ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      props.handleSubmit(constructResponseMap());
    }
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('bargain_price_id', bargainPriceId);
    temp.set('account_id', accountId);
    temp.set('sale_date', FormatDate(saleDate));
    temp.set('due_date', FormatDate(dueDate));
    temp.set('overridden_bargain_rate', overriddenBargainRate);
    temp.set('overridden_delivery_order_rate', overriddenDeliveryOrderRate);
    temp.set('quantity_in_quintals', quantity);
    temp.set('sell_order_id', sellOrderId);
    return temp;
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">Please enter a value for all fields</Alert>}
          </div>
          <Chip
            label={showAll === true ? 'Show Less': 'Show More' }
            size="medium"
            style={{ backgroundColor: showAll === true ? '#64b5f6': '#bdbdbd' }}
            onClick={() => setShowAll(!showAll) }
          />
          {(showAll || props.action === 'create') &&
            <FormControl className={classes.formControl}>
              <TextField id="quantity_in_quintals" label="Quantity (in quintals)" defaultValue={quantity} onChange={handleQuantity} />
            </FormControl>
          }
          <FormControl className={classes.formControl}>
            <TextField id="overridden_bargain_rate" label="Override Bargain Rate" defaultValue={overriddenBargainRate} onChange={handleOverriddenBargainRate} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="overridden_delivery_order_rate" label="Override D.O. Rate" defaultValue={overriddenDeliveryOrderRate} onChange={handleOverriddenDeliveryOrderRate} />
          </FormControl>
          {(showAll || props.action === "create") &&
            <FormControl className={classes.formControl}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={props.accounts && props.accounts.map((account) => (
                  `${account.name}, ${account.city}`
                ))}
                renderInput={(params) => <TextField {...params} label="Accounts" />}
                onChange={(event: any, newValue: string | null) => {
                  if(newValue !== null) {
                    var accountName = newValue.split(',')[0]
                    setAccountName(accountName);
                    setAccountId(getIdFromName(props.accounts, accountName));
                  } else {
                    setAccountName('');
                    setAccountId('');
                  }
                }}
              />
            </FormControl>
          }
          {(showAll || props.action === "create") &&
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog-sale-date"
                  label="Sale Date"
                  format="dd/MM/yyyy"
                  value={saleDate}
                  onChange={handleSaleDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          }
          <FormControl className={classes.formControl}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog-due-date"
                label="Due Date"
                format="dd/MM/yyyy"
                value={dueDate}
                onChange={handleDueDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
