import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import BargainsDialog from './bargains_dialog';
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../utils';
import SellOrderRow from './sell_order_row';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    width: '100%',
  },
  paperRoot: {
    backgroundColor: '#fafafa'
  }
}));

interface SellOrdersProps {
  tableName
  modelName
  columns
  accounts
  parties
  mills
  items
  qualities
  seasons
  bargains
  bargain_prices
  sell_orders
  delivery_orders

  handleCreateDeliveryOrder
  handleEditDeliveryOrder
  handleDeleteDeliveryOrder
  handleToggleLiftedDeliveryOrder
  handleTogglePaymentResolvedDeliveryOrder

  handleEditSellOrder
  handleDeleteSellOrder
};

export default function SellOrders(props: SellOrdersProps) {
  const classes = useStyles();

  // const filteredBargainPrices = (bargain_id) => {
  //   var result = bargainPrices.filter(bargainPrice => bargainPrice.bargain_id === bargain_id);
  //   return result;
  // };

  // const filteredSellOrders = (bargain_id) => {
  //   var bargainPriceIds = extractColumn(filteredBargainPrices(bargain_id), 'id');
  //   var result = sellOrders.filter(sellOrder => bargainPriceIds.includes(sellOrder.bargain_price_id));
  //   return result;
  // };

  const filteredDeliveryOrders = (sell_order_id) => {
    var result = props.delivery_orders.filter(deliveryOrder => deliveryOrder.sell_order_id === sell_order_id);
    return result;
  };

  const millNameForSellOrder = (sellOrder) => {
    var bargainPrice = getElementFromId(props.bargain_prices, sellOrder.bargain_price_id);
    var bargain = getElementFromId(props.bargains, bargainPrice.bargain_id);
    var mill = getElementFromId(props.mills, bargain.mill_id);
    return mill.name;
  };

  const accountNameForSellOrder = (sellOrder) => {
    var account = getElementFromId(props.accounts, sellOrder.account_id);
    return account.name;
  };
  
  return (
    <Paper variant="outlined">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>S.O. ID</TableCell>
              <TableCell align="left">Account name</TableCell>
              <TableCell align="left">Mill name</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left">Bargain Rate</TableCell>
              <TableCell align="left">D.O. Rate</TableCell>
              <TableCell align="left">Dates</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.sell_orders && props.sell_orders.map((sellOrder) => (
              <SellOrderRow 
                key={sellOrder.id} 
                sellOrder={sellOrder}
                filteredDeliveryOrders={filteredDeliveryOrders(sellOrder.id)}
                accountName={accountNameForSellOrder(sellOrder)}
                millName={millNameForSellOrder(sellOrder)}

                parties={props.parties}
                items={props.items}
                qualities={props.qualities}
                seasons={props.seasons}
                accounts={props.accounts}
                bargains={props.bargains}
                bargain_prices={props.bargain_prices}
                mills={props.mills}
                sell_orders={props.sell_orders}

                handleCreateDeliveryOrder={props.handleCreateDeliveryOrder}
                handleEditDeliveryOrder={props.handleEditDeliveryOrder}
                handleDeleteDeliveryOrder={props.handleDeleteDeliveryOrder}
                handleToggleLiftedDeliveryOrder={props.handleToggleLiftedDeliveryOrder}
                handleTogglePaymentResolvedDeliveryOrder={props.handleTogglePaymentResolvedDeliveryOrder}

                handleEditSellOrder={props.handleEditSellOrder}
                handleDeleteSellOrder={props.handleDeleteSellOrder}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
};
