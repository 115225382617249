export function getDisplayNameForDropdownFromId(list, id) {
  var result = list.filter(list_item => list_item.id == id)[0];
  if (typeof result !== 'undefined') {
    return result.name + ", " + result.city
  } else {
    return 'Error'
  }
};

export function getNameFromId(list, id) {
  var result = list.filter(list_item => list_item.id == id)[0];
  if (typeof result !== 'undefined') {
    return result.name
  } else {
    return 'Error'
  }
};

export function getIdFromName(list, name) {
  var result = list.filter(list_item => list_item.name === name)[0];
  if (typeof result !== 'undefined') {
    return result.id
  } else {
    return 'Error'
  }
};

export function getElementFromId(list, id) {
  var result = list.filter(list_item => list_item.id === id)[0];
  if (typeof result !== 'undefined') {
    return result
  } else {
    return 'Error'
  }
};

export function extractColumn(arr, column) {
  return arr.map(x => x[column])
};

export function formatAmount(amount) {
  var value = amount.toLocaleString(
    'hi-IN', // leave undefined to use the browser's locale,
              // or use a string like 'en-US' to override it.
    { minimumFractionDigits: 2 }
  );
  return value;  
  // In en-US, logs '100,000.00'
  // In de-DE, logs '100.000,00'
  // In hi-IN, logs '1,00,000.00'
};
