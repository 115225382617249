import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';
import { getDisplayNameForDropdownFromId, getElementFromId, extractColumn, formatAmount } from '../../utils';
import SimpleMenuForDeliveryOrderCard from './simple_menu_for_delivery_order_card';

interface DeliveryOrdersCardProps {
  sellOrder
  filteredDeliveryOrders

  accounts
  parties
  items
  qualities
  seasons
  bargains
  bargain_prices
  mills
  sell_orders

  handleEditDeliveryOrder
  handleDeleteDeliveryOrder
  
  handleToggleLiftedDeliveryOrder
  handleTogglePaymentResolvedDeliveryOrder
};

export default function DeliveryOrdersCard(props: DeliveryOrdersCardProps) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom component="div" align="center">
        Delivery Orders
      </Typography>
      <Table size="small" aria-label="prices">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Parties</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Quality</TableCell>
            <TableCell align="left">D.O. Rate</TableCell>
            <TableCell align="left">Remark</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.filteredDeliveryOrders && props.filteredDeliveryOrders.map((deliveryOrder) => (
            <TableRow key={deliveryOrder.id}>
              <TableCell component="th" scope="row">
                D.O. ID: {deliveryOrder.id}
                {/* <Box mt={0.4}>
                  <Chip
                    color={deliveryOrder.lifted === true ? 'primary' : 'secondary'}
                    variant="outlined"
                    size="small"
                    icon={<LocalShippingIcon />}
                    label={deliveryOrder.lifted === true ? 'Lifted' : 'Not Lifted'}
                    onClick={() => props.handleToggleLiftedDeliveryOrder(deliveryOrder.id)}
                  />
                </Box>
                <Box mt={0.4}>
                  <Chip
                    color={deliveryOrder.payment_resolved === true ? 'primary' : 'secondary'}
                    variant="outlined"
                    size="small"
                    icon={<PaymentIcon />}
                    label={deliveryOrder.payment_resolved === true ? 'Payment received' : 'Payment not received'}
                    onClick={() => props.handleTogglePaymentResolvedDeliveryOrder(deliveryOrder.id)}
                  />
                </Box> */}
              </TableCell>
              <TableCell align="left">
                Bill to: {getDisplayNameForDropdownFromId(props.parties, deliveryOrder.bill_to_id)}
                <br />
                Ship to: {getDisplayNameForDropdownFromId(props.parties, deliveryOrder.ship_to_id)}
              </TableCell>
              <TableCell align="left">{deliveryOrder.quantity_in_quintals}</TableCell>
              <TableCell align="left">
                <div>{deliveryOrder.item}</div>
                <div>{deliveryOrder.quality}</div>
                <div>{deliveryOrder.season}</div>
                <div>{deliveryOrder.plain_or_refined}</div>
              </TableCell>
              <TableCell align="left">{deliveryOrder.overridden_delivery_order_rate}</TableCell>
              <TableCell align="left">
                Remark: {deliveryOrder.remark}
                <br />
                Truck: {deliveryOrder.truck_number}
              </TableCell>
              {/* <TableCell align="left">
                <Typography 
                  color={+sellOrder.overridden_bargain_rate - +sellOrder.overridden_delivery_order_rate > 0 ? "initial" : "secondary" }
                >
                  {formatAmount(+sellOrder.quantity_in_quintals * (+sellOrder.overridden_bargain_rate - +sellOrder.overridden_delivery_order_rate))}
                </Typography>
              </TableCell> */}
              <TableCell align="left">
                <SimpleMenuForDeliveryOrderCard 
                  deliveryOrder={deliveryOrder}
                  sellOrder={getElementFromId(props.sell_orders, deliveryOrder.sell_order_id)}
  
                  bargainPrices={props.bargain_prices}
                  bargains={props.bargains}
                  mills={props.mills}
                  parties={props.parties}
                  accounts={props.accounts}
                  sell_orders={props.sell_orders}
                  items={props.items}
                  qualities={props.qualities}
                  seasons={props.seasons}
                  
                  handleEditDeliveryOrder={props.handleEditDeliveryOrder}
                  handleDeleteDeliveryOrder={props.handleDeleteDeliveryOrder}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Typography 
        color={totalProfitOrLoss > 0 ? "initial" : "secondary" }
      >
        Total P/L: {formatAmount(totalProfitOrLoss)}
      </Typography> */}
    </React.Fragment>
  )
};