import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormatDate from '../date_formatter'
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import BargainsDialog from './bargains_dialog'
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../utils'
import DeliveryOrdersCard from './delivery_orders_card/delivery_orders_card';
import Divider from '@material-ui/core/Divider';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DeliveryOrderDialog from './delivery_order_dialog'
import SimpleMenuForSellOrderCard from './sell_order_card/simple_menu_for_sell_order_card'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    width: '100%',
  },
  paperRoot: {
    backgroundColor: '#fafafa'
  },
  button: {
    margin: theme.spacing(1),
  },
}));

interface SellOrderRowProps {
  key
  sellOrder
  filteredDeliveryOrders
  millName
  accountName

  parties
  items
  qualities
  seasons
  accounts
  bargains
  bargain_prices
  mills
  sell_orders

  handleCreateDeliveryOrder
  handleEditDeliveryOrder
  handleDeleteDeliveryOrder
  handleToggleLiftedDeliveryOrder
  handleTogglePaymentResolvedDeliveryOrder

  handleEditSellOrder
  handleDeleteSellOrder
};

export default function SellOrderRow(props: SellOrderRowProps) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [openCreateDeliveryOrderDialog, setOpenCreateDeliveryOrderDialog] = React.useState(false);

  const closeDialogs = () => {
    setOpenCreateDeliveryOrderDialog(false);
  };

  var total_quantity_accounted_for = 0;
  props.filteredDeliveryOrders.forEach(function (deliveryOrder) {
    total_quantity_accounted_for += +deliveryOrder.quantity_in_quintals
  });
  var remaining_quantity_before_new_delivery_order = +props.sellOrder.quantity_in_quintals - total_quantity_accounted_for;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{props.sellOrder.id}</TableCell>
        <TableCell align="left">{props.accountName}</TableCell>
        <TableCell align="left">{props.millName}</TableCell>
        <TableCell align="left">
          {props.sellOrder.quantity_in_quintals}
          <br />
          Remaining: {remaining_quantity_before_new_delivery_order}
        </TableCell>
        <TableCell align="left">{props.sellOrder.overridden_bargain_rate}</TableCell>
        <TableCell align="left">{props.sellOrder.overridden_delivery_order_rate}</TableCell>
        <TableCell align="left">
          Sale date: {FormatDate(props.sellOrder.sale_date)}
          <br />
          Due date: {FormatDate(props.sellOrder.due_date)}
        </TableCell>
        <TableCell align="left">
          <SimpleMenuForSellOrderCard
            bargainPrices={props.bargain_prices}
            bargains={props.bargains}
            mills={props.mills}
            parties={props.parties}
            accounts={props.accounts}
            sellOrder={props.sellOrder}
            handleEditSellOrder={props.handleEditSellOrder}
            handleDeleteSellOrder={props.handleDeleteSellOrder}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<LocalShippingIcon />}
                        onClick={() => setOpenCreateDeliveryOrderDialog(true)}
                      >
                        Create D.O.
                      </Button>
                      <DeliveryOrderDialog
                        open={openCreateDeliveryOrderDialog}
                        onClose={closeDialogs}
                        dialogTitle="Create Delivery Order"
                        action="create"

                        sellOrder={props.sellOrder}
                        bargainPrice={getElementFromId(props.bargain_prices, props.sellOrder.bargain_price_id)}

                        parties={props.parties}
                        accounts={props.accounts}
                        items={props.items}
                        qualities={props.qualities}
                        seasons={props.seasons}

                        handleSubmit={props.handleCreateDeliveryOrder}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <Paper className={classes.paperRoot} elevation={6}>
                    <Box m={1} p={1}>
                      <DeliveryOrdersCard
                        sellOrder={props.sellOrder}
                        filteredDeliveryOrders={props.filteredDeliveryOrders}

                        accounts={props.accounts}
                        parties={props.parties}
                        items={props.items}
                        qualities={props.qualities}
                        seasons={props.seasons}
                        bargains={props.bargains}
                        bargain_prices={props.bargain_prices}
                        mills={props.mills}
                        sell_orders={props.sell_orders}

                        handleEditDeliveryOrder={props.handleEditDeliveryOrder}
                        handleDeleteDeliveryOrder={props.handleDeleteDeliveryOrder}
                        handleToggleLiftedDeliveryOrder={props.handleToggleLiftedDeliveryOrder}
                        handleTogglePaymentResolvedDeliveryOrder={props.handleTogglePaymentResolvedDeliveryOrder}
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};