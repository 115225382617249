import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import PartiesOrMillsDialog from './parties_or_mills_or_accounts_dialog';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';


const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
}));

interface PartiesTableProps {
  accountColumns
  partyColumns
};

export default function PartiesTable(props: PartiesTableProps) {
  const classes = useStyles();

  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false)
  const [parties, setParties] = useState([]);
  const [gstNumbers, setGstNumbers] = useState([]);
  const [newAccount, setNewAccount] = useState(new Map<string, string>());
  const [newParty, setNewParty] = useState(new Map<string, string>());
  const [editedParty, setEditedParty] = useState(new Map<string, string>());
  const [toDeletePartyId, setToDeletePartyId] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  useEffect(() => {
    axios.get('/parties.json')
      .then(response => {
        const data = response.data
        const gstNumbers = compileGstList(data)
        setGstNumbers(gstNumbers)
        setParties(data)
     })

      setRefreshPage(false);
  }, [refreshPage]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/parties/${editedParty['id']}.json`, editedParty, { headers })
           .then(response => {
              console.log('PUT parties, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [editedParty]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const account = constructMap(newAccount, props.accountColumns);
      axios.post(`/accounts.json`, account, { headers })
           .then(response => {
              console.log('POST accounts, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newAccount]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const party = constructMap(newParty, props.partyColumns);
      axios.post(`/parties.json`, party, { headers })
           .then(response => {
              console.log('POST parties, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [newParty]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/parties/${toDeletePartyId}.json`, { headers })
           .then(response => {
              console.log('DELETE parties, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [toDeletePartyId]);

  const compileGstList = (parties) => {
    const gstNumbers = []
    parties.forEach(function (party) {
      gstNumbers.push(party.gst_details);
    });
    return gstNumbers
  }

  const constructMap = (stateVariable, columns) => {
    var arr = []
    columns.forEach(function (columnName) {
      var map = new Map<string, string>();
      map['key'] = columnName;
      map['val'] = stateVariable.get(columnName);
      arr.push(map);
    });

    var result = arr.reduce(function(map, obj) {
      map[obj.key] = obj.val;
      return map;
    }, {});

    return result;
  }

  const handleEditParty = (response) => {
    var party = parties.filter(party => party.id == response.get('id'))[0];
    props.partyColumns.forEach(function (columnName) {
      party[columnName] = response.get(columnName)
    });
    setEditedParty(party)
  };

  const handleCreateAccount = (response) => {
    var columns = Array.from(response.keys());
    var temporaryMap = new Map<any, string>();
    columns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewAccount(temporaryMap);
  };

  const handleCreateParty = (response) => {
    var temporaryMap = createTemporaryMap(response)
    setNewParty(temporaryMap);
  };

  const createTemporaryMap = (response) => {
    var temporaryMap = new Map<any, any>();
    props.partyColumns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    return temporaryMap;
  };

  const handleDeleteParty = (party_id) => {
    setToDeletePartyId(party_id);
  };

  const [openCreatePartyDialog, setOpenCreatePartyDialog] = React.useState(false);

  const handleOpenCreatePartyDialog = () => {
    setOpenCreatePartyDialog(true);
  };

  const closeCreatePartyDialog = () => {
    setOpenCreatePartyDialog(false);
  };

  return (
    <Paper variant="outlined">
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleOpenCreatePartyDialog}
      >
        Create Party
      </Button>
      <PartiesOrMillsDialog
        open={openCreatePartyDialog}
        onClose={closeCreatePartyDialog}
        dialogTitle={"Create party"}
        handleSubmit={handleCreateParty}
        modelName="party"
        action="create"
        checkGstAlreadyExists={true}
        gstNumbers={gstNumbers}
      />
      <Box m={1.5}/>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {props.partyColumns && props.partyColumns.map((columnName, i) => (
                <TableCell key={columnName + i}>{columnName}</TableCell>
              ))}
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parties && parties.map((party) => (
              <TableRow key={party.id}>
                {props.partyColumns && props.partyColumns.map((columnName, j) => (
                  <TableCell key={j + columnName} component="th" scope="row">{party[columnName]}</TableCell>
                ))}
                <TableCell align="left">
                  <SimpleMenuForParty
                    party={party}
                    handleEditParty={handleEditParty}
                    handleDeleteParty={handleDeleteParty}
                    handleCreateAccount={handleCreateAccount}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
};

interface SimpleMenuForPartyProps {
  party
  handleEditParty
  handleDeleteParty
  handleCreateAccount
};

function SimpleMenuForParty(props: SimpleMenuForPartyProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openCopyToAccountDialog, setOpenCopyToAccountDialog] = React.useState(false);
  const [openEditPartyDialog, setOpenEditPartyDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCopyToAccountDialog = () => {
    setOpenCopyToAccountDialog(true);
    handleClose();
  };

  const handleOpenEditPartyDialog = () => {
    setOpenEditPartyDialog(true);
    handleClose();
  };

  const closeDialogs = () => {
    setOpenCopyToAccountDialog(false);
    setOpenEditPartyDialog(false);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenCopyToAccountDialog}>Create Account from this party</MenuItem>
        <MenuItem onClick={handleOpenEditPartyDialog}>Edit party</MenuItem>
        <MenuItem onClick={() => props.handleDeleteParty(props.party.id)}>Delete</MenuItem>
      </Menu>
      <PartiesOrMillsDialog
        open={openCopyToAccountDialog}
        onClose={closeDialogs}
        dialogTitle={"Create party from account"}
        handleSubmit={props.handleCreateAccount}
        party_or_mill={props.party}
        modelName="account"
        action="create"
        checkGstAlreadyExists={true}
      />
      <PartiesOrMillsDialog
        open={openEditPartyDialog}
        onClose={closeDialogs}
        dialogTitle={"Edit party"}
        handleSubmit={props.handleEditParty}
        party_or_mill={props.party}
        modelName="party"
        action="edit"
        checkGstAlreadyExists={true}
      />
    </React.Fragment>
  );
};