import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function PriceDialog(props) {
  const classes = useStyles();

  const [showAlert, setShowAlert] = useState(false);
  const [bargainPriceId, setBargainPriceId] = useState('');
  const [bargainId, setBargainId] = useState('');
  const [item, setItem] = useState('M-31');
  const [quality, setQuality] = useState('Dry and fresh');
  const [season, setSeason] = useState('2020-21');
  const [plainOrRefined, setPlainOrRefined] = useState('Plain');

  const bargainRateRef = useRef(null);
  const deliveryOrderRateRef = useRef(null);

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // If it is now open, but was previously not open
    if (open && !prevVisibility) {
      // Reset items here
      props.bargain_id && setBargainId(props.bargain_id);
      props.bargain_price && props.bargain_price.id && setBargainPriceId(props.bargain_price.id);
      props.bargain_price && props.bargain_price.item && setItem(props.bargain_price.item);
      props.bargain_price && props.bargain_price.quality && setQuality(props.bargain_price.quality);
      props.bargain_price && props.bargain_price.season && setSeason(props.bargain_price.season);
      props.bargain_price && props.bargain_price.plain_or_refined && setPlainOrRefined(props.bargain_price.plain_or_refined);
      setShowAlert(false);
    }
  }, [open, prevVisibility]);

  const handleItem = (event) => { setItem(event.target.value); };
  const handleQuality = (event) => { setQuality(event.target.value); };
  const handleSeason = (event) => { setSeason(event.target.value); };
  const handlePlainOrRefined = (event) => { setPlainOrRefined(event.target.value); };

  const handleSubmit = () => {
    if(bargainRateRef.current.value === '' || bargainId === '' || item === '' || quality === '' || season === '' || plainOrRefined === '') {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      props.handleSubmit(constructResponseMap());
    }
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('bargain_id', bargainId);
    temp.set('bargain_price_id', bargainPriceId);
    temp.set('bargain_rate', bargainRateRef.current.value);
    temp.set('delivery_order_rate', deliveryOrderRateRef.current.value);
    temp.set('item', item);
    temp.set('quality', quality);
    temp.set('season', season);
    temp.set('plain_or_refined', plainOrRefined);
    return temp;
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">Please enter a value for all fields</Alert>}
          </div>
          <Box />
          <FormControl className={classes.formControl}>
            <TextField id="bargain_rate" inputRef={bargainRateRef} label="Bargain Rate" defaultValue={props.bargain_price && props.bargain_price.bargain_rate} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="delivery_order_rate" inputRef={deliveryOrderRateRef} label="Delivery Order Rate" defaultValue={props.bargain_price && props.bargain_price.delivery_order_rate} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Item</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={item}
              onChange={handleItem}
            >
              {props.items && props.items.map((item) => (
                <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
              ))};
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Quality</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={quality}
              onChange={handleQuality}
            >
              {props.qualities && props.qualities.map((quality) => (
                <MenuItem key={quality.id} value={quality.value}>{quality.value}</MenuItem>
              ))};
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Season</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={season}
              onChange={handleSeason}
            >
              {props.seasons && props.seasons.map((season) => (
                <MenuItem key={season.id} value={season.value}>{season.value}</MenuItem>
              ))};
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Plain or refined</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plainOrRefined}
              onChange={handlePlainOrRefined}
            >
              <MenuItem key="Plain" value="Plain">Plain</MenuItem>
              <MenuItem key="Refined" value="Refined">Refined</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
