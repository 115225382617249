export default function FormatDate(datetime) {
  var date = new Date(datetime);
  var formattedDate = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  return formattedDate;
}

export function FormatPrettyDate(datetime) {
  var date = new Date(datetime);

  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  var month = monthNames[date.getMonth()];
  var formattedDate = date.getDate() + " " + month + " " + date.getFullYear();
  return formattedDate;
}