import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function DefaultDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [id, setId] = React.useState('');
  const [value, setValue] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
    if(props.id) {
      setId(props.id);
    }
    if(props.value) {
      setValue(props.value);
    }
    setShowAlert(false);
  };

  const handleValue = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setShowAlert(false);
    setOpen(false);
  };

  const handleSubmit = () => {
    if(value === '') {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      setOpen(false);
      props.handleSubmit(id, constructResponseMap());
    }
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('value', value);
    return temp;
  };

  return (
    <React.Fragment>
      <Button variant={props.buttonVariant} color={props.buttonColor} size="small" onClick={handleClickOpen}>
        {props.buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.buttonText}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">Please enter a value for all fields</Alert>}
          </div>
          <Box />
          <DialogContentText>
            {props.dialogSubtitle}
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <TextField id="value" label="Value" defaultValue={props.value} onChange={handleValue} autoFocus={true} />
          </FormControl>
          <Box />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
