import React from 'react';
import Box from '@material-ui/core/Box';
import DefaultTable from '../basic_models/default_table';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dashboard from './dashboard'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function DashboardWrapper() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Dashboard 
        modelName="bargain" 
        tableName="bargains" 
        columns={['id','account_id','mill_id','quantity_in_quintals','purchase_date','due_date','late_lifting_penalty_per_quintal']}
      />
    </div>
  )
}
