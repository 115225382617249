import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import BasicModels from './basic_models/basic_models';
import DashboardWrapper from './dashboard/dashboard_wrapper';
import SellOrders from './dashboard/sell_orders';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import LoginButton from './auth0/login_button'
import LogoutButton from './auth0/logout_button'
import ProfileForAppBar from './auth0/profile_for_app_bar'
import LoginPage from './login_page'
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import StorageIcon from '@material-ui/icons/Storage';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ReceiptIcon from '@material-ui/icons/Receipt';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function PermanentDrawerLeft() {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  const [showDashboard, setShowDashboard] = React.useState(true);
  const [showSellOrders, setShowSellOrders] = React.useState(false);
  const [showBasicModels, setShowBasicModels] = React.useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openProfileMenu = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSidebarClick = (item) => {
    switch(item) { 
      case "Database": { 
        setShowBasicModels(true);
        setShowDashboard(false);
        setShowSellOrders(false);
        break; 
      } 
      case "Dashboard": { 
        setShowBasicModels(false);
        setShowDashboard(true);
        setShowSellOrders(false);
        break; 
      } 
      case "Sell Orders": { 
        setShowBasicModels(false);
        setShowDashboard(false);
        setShowSellOrders(true);
        break; 
      } 
      default: { 
        setShowBasicModels(false);
        setShowDashboard(false);
        setShowSellOrders(false);
        break; 
      }
    }
  };

  const isSelected = (item) => {
    switch(item) {
      case "Database": {
        return showBasicModels;
      }
      case "Dashboard": {
        return showDashboard;
      }
      case "Sell Orders": {
        return showSellOrders;
      }
      default: {
        return false;
      }
    }
  };
  
  if(isLoading) {
    return <div>Loading...</div>
  }
  if (!isAuthenticated) {
    return <LoginPage />
  }

  return (
    isAuthenticated && (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Goyal Sugars, Meerut
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openProfileMenu}
              onClose={handleClose}
            >
              <MenuItem disabled={true}>
                <Typography variant="body2">Logged in as {user.email}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>Log out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem 
            button 
            key="Dashboard"
            selected={isSelected("Dashboard")}
            onClick={() => handleSidebarClick("Dashboard")}
          >
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem 
            button 
            key="Database"
            selected={isSelected("Database")}
            onClick={() => handleSidebarClick("Database")}
          >
            <ListItemIcon><StorageIcon /></ListItemIcon>
            <ListItemText primary="Database" />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
          {showBasicModels && <BasicModels />}
          {showDashboard && <DashboardWrapper />}
      </main>
    </div>
    )
  );
}

// document.addEventListener('DOMContentLoaded', () => {
//   ReactDOM.render(
//     <PermanentDrawerLeft />,
//     document.body.appendChild(document.createElement('div')),
//   )
// })
