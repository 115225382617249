import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Container from '@material-ui/core/Container';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import PartiesOrMillsDialog from './parties_or_mills_or_accounts_dialog';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';


const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
  },
}));

interface AccountsTableProps {
  accountColumns
  partyColumns
};

export default function AccountsTable(props: AccountsTableProps) {
  const classes = useStyles();

  // Initializing didMount as false
  const [didMount, setDidMount] = useState(false)
  const [accounts, setAccounts] = useState([]);
  const [newAccount, setNewAccount] = useState(new Map<string, string>());
  const [newParty, setNewParty] = useState(new Map<string, string>());
  const [editedAccount, setEditedAccount] = useState(new Map<string, string>());
  const [toDeleteAccountId, setToDeleteAccountId] = useState('');
  const [refreshPage, setRefreshPage] = useState(false);

  // Setting didMount to true upon mounting
  useEffect(() => { setDidMount(true) }, [])

  useEffect(() => {
    axios.get('/accounts.json')
      .then(response => {
        const data = response.data
        setAccounts(data)
     })

      setRefreshPage(false);
  }, [refreshPage]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.put(`/accounts/${editedAccount['id']}.json`, editedAccount, { headers })
           .then(response => {
              console.log('PUT accounts, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [editedAccount]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const account = constructMap(newAccount, props.accountColumns);
      axios.post(`/accounts.json`, account, { headers })
           .then(response => {
              console.log('POST accounts, Response from rails', response)
            })
      setRefreshPage(true);
    }
  }, [newAccount]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      const party = constructMap(newParty, props.partyColumns);
      axios.post(`/parties.json`, party, { headers })
           .then(response => {
              console.log('POST parties, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [newParty]);

  useEffect(() => {
    if(didMount) {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf
      };
      axios.delete(`/accounts/${toDeleteAccountId}.json`, { headers })
           .then(response => {
              console.log('DELETE accounts, Response from rails', response)
           })
      setRefreshPage(true);
    }
  }, [toDeleteAccountId]);

  const constructMap = (stateVariable, columns) => {
    var arr = []
    columns.forEach(function (columnName) {
      var map = new Map<string, string>();
      map['key'] = columnName;
      map['val'] = stateVariable.get(columnName);
      arr.push(map);
    });

    var result = arr.reduce(function(map, obj) {
      map[obj.key] = obj.val;
      return map;
    }, {});

    return result;
  }

  const handleEditAccount = (response) => {
    var account = accounts.filter(account => account.id == response.get('id'))[0];
    props.accountColumns.forEach(function (columnName) {
      account[columnName] = response.get(columnName)
    });
    setEditedAccount(account)
  };

  const handleCreateAccount = (response) => {
    var temporaryMap = new Map<any, any>();
    props.accountColumns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewAccount(temporaryMap);
  };

  const handleCreateParty = (response) => {
    var temporaryMap = new Map<any, any>();
    props.partyColumns.forEach(function (columnName) {
      temporaryMap.set(columnName, response.get(columnName));
    });
    setNewParty(temporaryMap);
  };

  const handleDeleteAccount = (account_id) => {
    setToDeleteAccountId(account_id);
  };

  const [openCreateAccountDialog, setOpenCreateAccountDialog] = React.useState(false);

  const handleOpenCreateAccountDialog = () => {
    setOpenCreateAccountDialog(true);
  };

  const closeCreateAccountDialog = () => {
    setOpenCreateAccountDialog(false);
  };

  return (
    <Paper variant="outlined">
      <Button
        variant='contained'
        color='primary'
        size='small'
        onClick={handleOpenCreateAccountDialog}
      >
        Create Account
      </Button>
      <PartiesOrMillsDialog
        open={openCreateAccountDialog}
        onClose={closeCreateAccountDialog}
        dialogTitle={"Create account"}
        handleSubmit={handleCreateAccount}
        modelName="account"
        action="create"
        checkGstAlreadyExists={false}
      />
      <Box m={1.5}/>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {props.accountColumns && props.accountColumns.map((columnName, i) => (
                <TableCell key={columnName + i}>{columnName}</TableCell>
              ))}
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts && accounts.map((account) => (
              <TableRow key={account.id}>
                {props.accountColumns && props.accountColumns.map((columnName, j) => (
                  <TableCell key={j + columnName} component="th" scope="row">{account[columnName]}</TableCell>
                ))}
                <TableCell align="left">
                  <SimpleMenuForAccount
                    account={account}
                    handleEditAccount={handleEditAccount}
                    handleDeleteAccount={handleDeleteAccount}
                    handleCreateParty={handleCreateParty}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
};

interface SimpleMenuForAccountProps {
  account
  handleEditAccount
  handleDeleteAccount
  handleCreateParty
};

function SimpleMenuForAccount(props: SimpleMenuForAccountProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openCopyToPartyDialog, setOpenCopyToPartyDialog] = React.useState(false);
  const [openEditAccountDialog, setOpenEditAccountDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCopyToPartyDialog = () => {
    setOpenCopyToPartyDialog(true);
    handleClose();
  };

  const handleOpenEditAccountDialog = () => {
    setOpenEditAccountDialog(true);
    handleClose();
  };

  const closeDialogs = () => {
    setOpenCopyToPartyDialog(false);
    setOpenEditAccountDialog(false);
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenCopyToPartyDialog}>Create Party from this account</MenuItem>
        <MenuItem onClick={handleOpenEditAccountDialog}>Edit Account</MenuItem>
        <MenuItem onClick={() => props.handleDeleteAccount(props.account.id)}>Delete</MenuItem>
      </Menu>
      <PartiesOrMillsDialog
        open={openCopyToPartyDialog}
        onClose={closeDialogs}
        dialogTitle={"Create party from account"}
        handleSubmit={props.handleCreateParty}
        party_or_mill={props.account}
        modelName="party"
        action="create"
        checkGstAlreadyExists={false}
      />
      <PartiesOrMillsDialog
        open={openEditAccountDialog}
        onClose={closeDialogs}
        dialogTitle={"Edit account"}
        handleSubmit={props.handleEditAccount}
        party_or_mill={props.account}
        modelName="account"
        action="edit"
        checkGstAlreadyExists={false}
      />
    </React.Fragment>
  );
};