import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../../utils'
import SimpleMenuForSellOrderCard from './simple_menu_for_sell_order_card'

interface SellOrderCardProps {
  filteredSellOrders
  parties
  accounts
  filteredBargainPrices
  bargainPrices
  bargains
  mills
  
  handleCreateSellOrder
  handleEditSellOrder
  handleDeleteSellOrder
};

export default function SellOrderCard(props: SellOrderCardProps) {
  var totalProfitOrLoss = 0.0;
  props.filteredSellOrders.forEach(function (sellOrder) {
    totalProfitOrLoss += +sellOrder.quantity_in_quintals * (+sellOrder.overridden_bargain_rate - +sellOrder.overridden_delivery_order_rate)
  });

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom component="div" align="center">
        Sales made against this purchase
      </Typography>
      <Table size="small" aria-label="prices">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="left">Account</TableCell>
            <TableCell align="left">Quantity</TableCell>
            <TableCell align="left">Quality</TableCell>
            <TableCell align="left">Rates</TableCell>
            <TableCell align="left">P/L</TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.filteredSellOrders && props.filteredSellOrders.map((sellOrder) => (
            <TableRow key={sellOrder.id}>
              <TableCell component="th" scope="row">S.O. ID: {sellOrder.id}</TableCell>
              <TableCell align="left">
                {getNameFromId(props.accounts, sellOrder.account_id)}
              </TableCell>
              <TableCell align="left">{sellOrder.quantity_in_quintals}</TableCell>
              <TableCell align="left">
                <div>{getElementFromId(props.filteredBargainPrices, sellOrder.bargain_price_id).item}</div>
                <div>{getElementFromId(props.filteredBargainPrices, sellOrder.bargain_price_id).quality}</div>
                <div>{getElementFromId(props.filteredBargainPrices, sellOrder.bargain_price_id).season}</div>
                <div>{getElementFromId(props.filteredBargainPrices, sellOrder.bargain_price_id).plain_or_refined}</div>
              </TableCell>
              <TableCell align="left">
                <div>Bargain rate: {sellOrder.overridden_bargain_rate}</div>
                <div>D.O. rate: {sellOrder.overridden_delivery_order_rate}</div>
              </TableCell>
              <TableCell align="left">
                <Typography 
                  color={+sellOrder.overridden_bargain_rate - +sellOrder.overridden_delivery_order_rate > 0 ? "initial" : "secondary" }
                >
                  {formatAmount(+sellOrder.quantity_in_quintals * (+sellOrder.overridden_bargain_rate - +sellOrder.overridden_delivery_order_rate))}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <SimpleMenuForSellOrderCard 
                  bargainPrices={props.bargainPrices}
                  bargains={props.bargains}
                  mills={props.mills}
                  parties={props.parties}
                  accounts={props.accounts}
                  sellOrder={sellOrder}

                  handleEditSellOrder={props.handleEditSellOrder}
                  handleDeleteSellOrder={props.handleDeleteSellOrder}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography 
        color={totalProfitOrLoss > 0 ? "initial" : "secondary" }
      >
        Total P/L: {formatAmount(totalProfitOrLoss)}
      </Typography>
    </React.Fragment>
  )
};