import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { getDisplayNameForDropdownFromId } from '../utils'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormatDate from '../date_formatter'
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface DeliveryOrderDialogProps {
  open
  onClose
  dialogTitle
  action

  deliveryOrder?
  sellOrder
  bargainPrice? // only needed when action=="create"

  parties
  accounts
  items
  qualities
  seasons

  handleSubmit
};

export default function DeliveryOrderDialog(props: DeliveryOrderDialogProps) {
  const classes = useStyles();

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // If it is now open, but was previously not open
    if (open && !prevVisibility) {
      // Reset items here
      setSellOrderId(props.sellOrder.id);
      if(props.action === 'create') {
        props.sellOrder && props.sellOrder.overridden_delivery_order_rate && setOverriddenDeliveryOrderRate(props.sellOrder.overridden_delivery_order_rate);
        props.bargainPrice && props.bargainPrice.item && setItem(props.bargainPrice.item);
        props.bargainPrice && props.bargainPrice.quality && setQuality(props.bargainPrice.quality);
        props.bargainPrice && props.bargainPrice.season && setSeason(props.bargainPrice.season);
        props.bargainPrice && props.bargainPrice.plain_or_refined && setPlainOrRefined(props.bargainPrice.plain_or_refined);
      } else if(props.action === 'edit') {
        props.deliveryOrder && props.deliveryOrder.id && setDeliveryOrderId(props.deliveryOrder.id);
        props.deliveryOrder && props.deliveryOrder.quantity_in_quintals && setQuantity(props.deliveryOrder.quantity_in_quintals);
        props.deliveryOrder && props.deliveryOrder.overridden_delivery_order_rate && setOverriddenDeliveryOrderRate(props.deliveryOrder.overridden_delivery_order_rate);
        props.deliveryOrder && props.deliveryOrder.truck_number && setTruckNumber(props.deliveryOrder.truck_number);
        props.deliveryOrder && props.deliveryOrder.remark && setRemark(props.deliveryOrder.remark);
        props.deliveryOrder && props.deliveryOrder.bill_to_id && setBillToId(props.deliveryOrder.bill_to_id);
        props.deliveryOrder && props.deliveryOrder.bill_to_id && setBillToName(getDisplayNameForDropdownFromId(props.parties, props.deliveryOrder.bill_to_id));
        props.deliveryOrder && props.deliveryOrder.ship_to_id && setShipToId(props.deliveryOrder.ship_to_id);
        props.deliveryOrder && props.deliveryOrder.ship_to_id && setShipToName(getDisplayNameForDropdownFromId(props.parties, props.deliveryOrder.ship_to_id));
        props.deliveryOrder && props.deliveryOrder.item && setItem(props.deliveryOrder.item);
        props.deliveryOrder && props.deliveryOrder.quality && setQuality(props.deliveryOrder.quality);
        props.deliveryOrder && props.deliveryOrder.season && setSeason(props.deliveryOrder.season);
        props.deliveryOrder && props.deliveryOrder.plain_or_refined && setPlainOrRefined(props.deliveryOrder.plain_or_refined);
        props.deliveryOrder && props.deliveryOrder.tcs_or_tds && setTcsOrTds(props.deliveryOrder.tcs_or_tds);
        props.deliveryOrder && props.deliveryOrder.do_date && setDoDate(props.deliveryOrder.do_date);
        props.deliveryOrder && props.deliveryOrder.do_number && setDoNumber(props.deliveryOrder.do_number);
      }

      setShowAlert(false);
    }
  }, [open, prevVisibility]);

  const [showAlert, setShowAlert] = useState(false);
  const [deliveryOrderId, setDeliveryOrderId] = useState('');
  const [sellOrderId, setSellOrderId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [overriddenDeliveryOrderRate, setOverriddenDeliveryOrderRate] = useState('');
  const [truckNumber, setTruckNumber] = useState('');
  const [remark, setRemark] = useState('');
  const [billToId, setBillToId] = useState('');
  const [billToName, setBillToName] = useState('');
  const [shipToId, setShipToId] = useState('');
  const [shipToName, setShipToName] = useState('');
  const [item, setItem] = useState('');
  const [quality, setQuality] = useState('');
  const [season, setSeason] = useState('');
  const [plainOrRefined, setPlainOrRefined] = useState('');
  const [tcsOrTds, setTcsOrTds] = useState('');
  const [doDate, setDoDate] = useState('');
  const [doNumber, setDoNumber] = useState('');

  const handleQuantity = (event) => {
    setQuantity(event.target.value);
  };
  const handleOverriddenDeliveryOrderRate = (event) => {
    setOverriddenDeliveryOrderRate(event.target.value);
  };
  const handleRemark = (event) => {
    setRemark(event.target.value);
  };
  const handleTruckNumber = (event) => {
    setTruckNumber(event.target.value);
  };
  const handleItem = (event) => {
    setItem(event.target.value);
  };
  const handleQuality = (event) => {
    setQuality(event.target.value);
  };
  const handleSeason = (event) => {
    setSeason(event.target.value);
  };
  const handlePlainOrRefined = (event) => {
    setPlainOrRefined(event.target.value);
  };
  const handleTcsOrTds = (event) => {
    setTcsOrTds(event.target.value);
  };
  const handleDoDate = (event) => {
    setDoDate(event.target.value);
  };
  const handleDoNumber = (event) => {
    setDoNumber(event.target.value);
  };

  const handleSubmit = () => {
    if( sellOrderId === '' ||
        quantity === '' ||
        overriddenDeliveryOrderRate === ''
      ) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      props.handleSubmit(constructResponseMap());
    }
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('delivery_order_id', deliveryOrderId);
    temp.set('sell_order_id', sellOrderId);
    temp.set('overridden_delivery_order_rate', overriddenDeliveryOrderRate);
    temp.set('quantity_in_quintals', quantity);
    temp.set('bill_to_id', billToId);
    temp.set('ship_to_id', shipToId);
    temp.set('remark', remark);
    temp.set('truck_number', truckNumber);
    temp.set('item', item);
    temp.set('quality', quality);
    temp.set('season', season);
    temp.set('plain_or_refined', plainOrRefined);
    temp.set('tcs_or_tds', tcsOrTds);
    temp.set('do_date', doDate);
    temp.set('do_number', doNumber);
    return temp;
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            {showAlert && <Alert severity="error">Please enter a value for all fields</Alert>}
          </div>
          <FormControl className={classes.formControl}>
            <TextField id="do_date" label="D.O. Date (DD/MM/YYYY)" defaultValue={doDate} onChange={handleDoDate} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="do_number" label="D.O. Number" defaultValue={doNumber} onChange={handleDoNumber} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="quantity_in_quintals" label="Quantity (in quintals)" defaultValue={quantity} onChange={handleQuantity} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="overridden_delivery_order_rate" label="Override D.O. Rate" defaultValue={overriddenDeliveryOrderRate} onChange={handleOverriddenDeliveryOrderRate} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Item</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={item}
              onChange={handleItem}
            >
              {props.items && props.items.map((item) => (
                <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>
              ))};
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Quality</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={quality}
              onChange={handleQuality}
            >
              {props.qualities && props.qualities.map((quality) => (
                <MenuItem key={quality.id} value={quality.value}>{quality.value}</MenuItem>
              ))};
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Season</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={season}
              onChange={handleSeason}
            >
              {props.seasons && props.seasons.map((season) => (
                <MenuItem key={season.id} value={season.value}>{season.value}</MenuItem>
              ))};
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Plain or refined</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={plainOrRefined}
              onChange={handlePlainOrRefined}
            >
              <MenuItem key="Plain" value="Plain">Plain</MenuItem>
              <MenuItem key="Refined" value="Refined">Refined</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={props.parties && props.parties.map((party) => (
                  `${party.name}, ${party.city}, ${party.id}`
                ))}
                renderInput={(params) => <TextField {...params} label="Bill To" />}
                onChange={(event: any, newValue: string | null) => {
                  if(newValue !== null) {
                    var billToName = newValue.split(',')[0]
                    var billToId = newValue.split(',')[2]
                    setBillToName(billToName);
                    setBillToId(billToId);
                  } else {
                    setBillToName('');
                    setBillToId('');
                  }
                }}
              />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={props.parties && props.parties.map((party) => (
                  `${party.name}, ${party.city}, ${party.id}`
                ))}
                renderInput={(params) => <TextField {...params} label="Ship To" />}
                onChange={(event: any, newValue: string | null) => {
                  if(newValue !== null) {
                    var shipToName = newValue.split(',')[0]
                    var shipToId = newValue.split(',')[2]
                    setShipToName(shipToName);
                    setShipToId(shipToId);
                  } else {
                    setShipToName('');
                    setShipToId('');
                  }
                }}
              />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="remark" label="Remark" defaultValue={remark} onChange={handleRemark} />
            <TextField id="truck_number" label="Truck Number" defaultValue={truckNumber} onChange={handleTruckNumber} />
          </FormControl>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button variant={tcsOrTds === 'tcs' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('tcs')}>TCS</Button>
            <Button variant={tcsOrTds === 'tds' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('tds')}>TDS</Button>
            <Button variant={tcsOrTds === 'none' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('none')}>Not applicable</Button>
          </ButtonGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
