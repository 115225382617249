import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { getNameFromId, getIdFromName } from '../../utils'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormatDate from '../../date_formatter'
import Chip from '@material-ui/core/Chip';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface TcsOrTdsDialogProps {
  open
  onClose
  dialogTitle
  
  handleSubmit
};

export default function TcsOrTdsDialog(props: TcsOrTdsDialogProps) {
  const classes = useStyles();

  const open = props.open;
  const prevVisibility = usePrevious(open);
  useEffect(() => {
    // If it is now open, but was previously not open
    if (open && !prevVisibility) {
      // Reset items here
      setShowAlert(false);
    }
  }, [open, prevVisibility]);

  const [showAlert, setShowAlert] = useState(false);
  const [tcsOrTds, setTcsOrTds] = useState('');

  const handleSubmit = () => {
    props.handleSubmit(constructResponseMap());
  };

  const constructResponseMap = () => {
    var temp = new Map<string, string>();
    temp.set('tcs_or_tds', tcsOrTds);
    return temp;
  };

  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <ButtonGroup color="primary" aria-label="outlined primary button group">
              <Button variant={tcsOrTds === 'tcs' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('tcs')}>TCS</Button>
              <Button variant={tcsOrTds === 'tds' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('tds')}>TDS</Button>
              <Button variant={tcsOrTds === 'none' ? 'contained' : 'outlined'} onClick={() => setTcsOrTds('none')}>Not applicable</Button>
            </ButtonGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
