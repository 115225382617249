import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SellOrderDialog from '../sell_order_dialog'
import DeliveryOrder from '../delivery_order/delivery_order'
import { getNameFromId, getElementFromId, extractColumn, formatAmount } from '../../utils';

interface SimpleMenuForSellOrderCardProps {
  bargainPrices
  bargains
  mills
  parties
  accounts
  sellOrder

  handleEditSellOrder
  handleDeleteSellOrder
}

export default function SimpleMenuForSellOrderCard(props: SimpleMenuForSellOrderCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEditSellOrderDialog, setOpenEditSellOrderDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditSellOrderDialog = () => {
    setOpenEditSellOrderDialog(true);
    handleClose();
  };
  
  const closeDialogs = () => {
    setOpenEditSellOrderDialog(false);
  };

  const getAssociatedRecords = (sellOrder) => {
    var bargain_price = getElementFromId(props.bargainPrices, sellOrder.bargain_price_id);
    var bargain = getElementFromId(props.bargains, bargain_price.bargain_id);
    var mill = getElementFromId(props.mills, bargain.mill_id);
    var account = getElementFromId(props.accounts, sellOrder.account_id);
    var bill_to_party = getElementFromId(props.parties, sellOrder.bill_to_id);
    var ship_to_party = getElementFromId(props.parties, sellOrder.ship_to_id);
    return {
      bargain_price: bargain_price,
      bargain: bargain,
      mill: mill,
      account: account,
      bill_to_party: bill_to_party,
      ship_to_party: ship_to_party,
    }
  };

  return (
    <React.Fragment>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenEditSellOrderDialog}>Edit Sell Order</MenuItem>
        <MenuItem onClick={() => props.handleDeleteSellOrder(props.sellOrder.id)}>Delete Sell Order</MenuItem>
      </Menu>
      <SellOrderDialog 
        open={openEditSellOrderDialog}
        onClose={closeDialogs}
        dialogTitle="Edit Sell Order" 
        handleSubmit={props.handleEditSellOrder} 
        bargain_price={getElementFromId(props.bargainPrices, props.sellOrder.bargain_price_id)}
        bargain={getElementFromId(props.bargains, getElementFromId(props.bargainPrices, props.sellOrder.bargain_price_id).id)}
        sell_order={props.sellOrder}
        parties={props.parties}
        accounts={props.accounts}
        action="edit"
      />
    </React.Fragment>
  );
};